/* RandomLoader.css */
.lp-loader-container {
    padding: 0;
    width: 150px;
    margin: auto;
    height: 150px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lp-loader-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}