.ud-unapproved-designs {
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
}

.ud-unapproved-designs h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
    font-size: 2rem;
}

.ud-loading,
.ud-error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
}

.ud-error {
    color: #dc3545;
}

/* Masonry Grid Styles */
.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

/* Design Card Styles */
.ud-design-card {
    background: var(--background-secondary);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

.ud-design-info {
    padding: 1.5rem;
}

.ud-design-type {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
}

.ud-label {
    font-weight: 600;
    margin-right: 0.5rem;
    color: #666;
}

.ud-value {
    color: #333;
}

.ud-design-details {
    margin-bottom: 1.5rem;
}

.ud-design-details p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    line-height: 1.4;
    padding: 0.5rem;
}

.ud-design-details strong {
    color: #555;
    margin-right: 0.5rem;
}

/* Action Buttons */
.ud-actions {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #eee;
}

.ud-approve-btn,
.ud-reject-btn {
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.ud-approve-btn {
    background-color: #28a745;
    color: white;
}

.ud-approve-btn:hover {
    background-color: #218838;
}

.ud-reject-btn {
    background-color: #dc3545;
    color: white;
}

.ud-reject-btn:hover {
    background-color: #c82333;
}

.ud-design-details p {
    max-height: 100px;
    overflow-y: auto;
    border: 2px solid #000;
}

.ud-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ud-bulk-actions {
    display: flex;
    gap: 10px;
}

.ud-reject-all-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.ud-reject-all-btn:hover {
    background-color: #c82333;
}

.ud-reject-all-btn:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.ud-edit-design {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

.ud-edit-field {
    display: flex;
    flex-direction: column;
}

.ud-edit-field label {
    margin-bottom: 5px;
    font-weight: bold;
}

.ud-edit-field select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ud-edit-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.ud-edit-btn {
    background-color: #f0ad4e;
    color: white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .ud-unapproved-designs {
        padding: 1rem;
    }

    .ud-actions {
        flex-direction: column;
    }

    .ud-approve-btn,
    .ud-reject-btn {
        width: 100%;
    }
}

/* Animation for loading state */
@keyframes pulse {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.3;
    }
}

.ud-loading {
    animation: pulse 1.5s infinite ease-in-out;
}