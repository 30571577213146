.social-link {
    align-items: center;
    text-decoration: none;
    color: inherit;
    margin-right: 1rem;
}

@media (max-width: 786px) {
    .social-link{
        margin: 0 auto;
        padding: 10px;
    }
}   