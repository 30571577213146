footer {
  position: relative;
  bottom: 0;
  color: var(--text-primary);
  height: fit-content;
  max-width: 1200px;
  margin: auto;
  background: var(--background-secondary);
}

.blog-container footer{
  margin-top: 200px;
}

footer a {
  text-decoration: none;
  color: var(--text-secondary);
  transition: color 0.1s ease-out;
}

footer a:hover {
  color: var(--text-primary);
}

.Mid {
  padding: 100px 17px 60px 7px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}

.Mid>div {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  align-items: center;
}

.Mid h3 {
  padding: 10px 0;
  text-transform: uppercase;
}

.more-services-f {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: start;
}

.more-services-f>div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.more-services-f svg:hover path {
  stroke: none;
  fill: var(--button-background);
}

.more-services-f a:last-child svg {
  margin-left: -4px;
}

.more-services-f a:last-child svg:hover path:nth-child(2) {
  fill: var(--background-secondary);
}

.more-services-f .links {
  display: flex;
  gap: 6px;
}

.bottom {
  border-top: 1px solid var(--border-color);
  padding: 50px 30px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.footer-credit {
  display: flex;
  gap: 7px;
}

footer pre {
  font-family: "Montserrat", sans-serif;
}

footer .logo {
  justify-content: start;
}

footer #favicon {
  width: 43px;
}

.logo img {
  width: 170px;
  border-radius: 0.5rem;
}

/* Mobile */
@media (max-width: 768px) {
  footer {
    margin-top: 100px;
    margin-bottom: 70px;
  }

  .Mid {
    padding: 10px 5px 30px 5px;
    display: grid;
    grid-template-columns: 1fr;
  }

  footer .logo img {
    width: 100px;
  }

  .Mid>div {
    width: 100%;
    flex-direction: column;
    gap: 8px;
    min-width: 150px;
  }

  footer .logo {
    width: fit-content;
    margin-left: 1.4rem;
  }

  .bottom {
    padding: 30px 10px;
    gap: 10px;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
  }

  .Mid>div>div {
    width: 70%;
  }
}