.home {
  height: fit-content;
  min-height: 100vh;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding-top: 20px;
  display: flex;
}

.home>div {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  padding: 20px 14px;
  height: calc(100vh - 100px);
}

.left {
  text-align: start;
  flex-direction: column;
  gap: 20px;
}

.landing-p {
  letter-spacing: 1px;
  line-height: 25px;
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.big-h {
  font-size: 3.5rem;
  font-weight: 900;
}

@keyframes moveBackground {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.left h1 span {
  color: #333;
  text-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1),  
    6px 6px 10px rgba(0, 0, 0, 0.07), 
    10px 10px 15px rgba(0, 0, 0, 0.05), 
    15px 15px 20px rgba(0, 0, 0, 0.03), 
    20px 20px 30px rgba(0, 0, 0, 0.01); 
}


.left p {
  font-size: 1.2rem;
}

.right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.right>div {
  height: 80%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  pointer-events: none;
  box-shadow:
    inset 0 80px 40px -10px var(--background-secondary),
    inset 0 -80px 40px -10px var(--background-secondary);
}

.right>div::-webkit-scrollbar {
  display: none;
}

.item {
  min-height: 120px;
  z-index: -1;
  user-select: none;
  padding: 10px 20px;
  font-weight: 800;
  border-radius: 14px;
  background: var(--background-primary);
  box-shadow: -2px -2px 3px 0 var(--card-shadow),
    2px 2px 3px 0 var(--card-shadow);
}

.first {
  padding: 20px 10px 20px 30px;
}

.second {
  padding: 20px 30px 20px 10px;
}

.home-buttons {
  position: sticky;
  bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.home-buttons a {
  text-decoration: none;
  border-radius: 0.3rem;
  padding: 1rem 1rem;
  color: var(--button-text);
  width: fit-content;
  min-width: 150px;
  text-align: center;
  background: var(--button-background);

  &:hover {
    background: var(--primary-700);
  }
}

.about-top-bar {
  width: 80%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  margin-bottom: 20px;
  z-index: 50;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {}

/* Mobile */
@media (max-width: 768px) {

  .about-top-bar {
    padding: 0.1rem;
    right: 0.5rem;
  }

  .big-h {
    font-size: 2.7rem;
    font-weight: 900;
  }

  .home {
    padding-top: 0px;
    min-height: fit-content;
    flex-direction: column;
  }

  .left p {
    font-size: 1rem;
  }

  .left {
    height: fit-content;
    max-height: fit-content;
    min-height: 0;
    height: fit-content !important;
    margin: 30px auto;
    margin-top: 60px;
    margin-bottom: 0;
  }

  .first,
  .second {
    padding: 0;
    margin: 0;
  }

  .right.mobile {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0 !important;
    gap: 0px !important;
    min-width: 100px;
    margin-bottom: 20px;
  }

  .right.mobile>div {
    width: 95%;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px 0 !important;
    margin: 0;
    height: auto;
    box-shadow:
      inset 80px 0 40px -10px var(--background-secondary),
      inset -80px 0 40px -10px var(--background-secondary);
  }

  .right.mobile {
    height: fit-content;
    max-height: fit-content;
  }

  .right.mobile .item {
    min-width: fit-content;
    height: fit-content;
    min-height: 50px;
    border-radius: 3px;
    margin: 0;
  }
}

/* Small Mobile */
@media (max-width: 400px) {}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {}

.about-home {
  max-width: 1200px;
}