.header-message-stack {
    position: fixed;
    top: 1rem;
    right: 1rem;
    max-width: 350px;
    z-index: 2002;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content; 
    min-width: 250px;
}

.header-message {
    background-color: var(--background-primary);
    box-shadow: var(--card-shadow);
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
}

.hm-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: var(--text-secondary);
    transition: color 0.3s ease;
}

.hm-close-button:hover {
    color: var(--text-primary);
}

.hm-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.hm-content {
    flex-grow: 1;
}

.hm-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.hm-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: var(--text-secondary);
    line-height: 1.4;
}

.hm-button {
    padding: 0.5rem 1rem;
    background-color: var(--success);
    color: var(--primary-100);
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    align-self: flex-end;
}

.hm-button:hover {
    background-color: var(--success);
    opacity: 0.9;
    transform: translateY(-1px);
}

.hm-button:active {
    transform: translateY(1px);
}

.hm-instagram-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1306C;
}

.hm-instagram-button:hover {
    background-color: #C13584;
}

.hm-instagram-button svg {
    margin-right: 0.5rem;
}

/* Type-specific styles */
.header-message.follow .hm-icon,
.header-message.instagram .hm-icon {
    color: #E1306C;
}

.header-message.info .hm-icon {
    color: var(--info);
}

.header-message.promotion .hm-icon {
    color: var(--warning);
}

.header-message.contest .hm-icon {
    color: var(--primary-brand-color-bright);
}

.header-message.community .hm-icon {
    color: var(--success);
}

.header-message.warning .hm-icon {
    color: var(--warning);
}

@media (max-width: 768px) {
    .header-message-stack {
        left: 1rem;
        right: 1rem;
        max-width: none;
    }

    .header-message {
        max-width: none;
    }
}