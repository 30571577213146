.header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    overflow-y: auto;
    background-color: var(--background-primary);
    box-shadow: var(--card-shadow);
    z-index: 2000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    width: 250px;
}

.header-sidebar.open {
    transform: translateX(0);
}

.header-sidebar-toggle {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 2001;
    background: var(--background-primary);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: var(--card-shadow);
    color: var(--text-primary);
    transition: all 0.3s ease-in-out;
}

.header-sidebar-toggle.open {
    left: 1rem;
}

.header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.header-overlay.visible {
    opacity: 1;
    visibility: visible;
}

.header-nav-container {
    padding: 2rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header-nav-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 4rem;
}

.header-nav-items-bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.bottom-nav-item {
    padding: 0.75rem 1.5rem;
}

.bottom-nav-item .header-nav-label {
    font-size: 0.8rem;
}

.header-nav-container a {
    text-decoration: none;
    width: 100%;
}

.header-nav-item {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    transition: background-color 0.3s, color 0.3s;
    color: var(--text-primary);
    cursor: pointer;
    width: 100%;
}

.header-nav-item:hover {
    background-color: var(--background-secondary);
}

.header-nav-item.active {
    color: var(--text-primary);
    background-color: var(--header-active-button);
}

.header-nav-item-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.header-nav-label {
    font-size: 0.875rem;
}

.header-nav-notification-dot {
    position: absolute;
    top: -2px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    animation: pulse2 1s infinite;
}


.header-filter-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2199;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    cursor: pointer;
    pointer-events: none;
}

.header-filter-container.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.header-filter-count-span {
    border: 5px solid var(--background-secondary);
    margin-left: 10px;
    padding: 2px 6px;
    background: var(--button-background);
    border-radius: 20px;
    font-size: 0.8rem;
    color: var(--button-text);
}

@keyframes pulse2 {
    0% {
        transform: scale(0.3);
        opacity: 0.01;
        background-color: white;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
        background-color: red;
    }

    100% {
        transform: scale(0.3);
        opacity: 0.01;
        background-color: white;
    }
}

/* Responsive styles */
@media (min-width: 1440px) {
    .header-sidebar {
        transform: translateX(-100%);
    }

    .header-sidebar.open {
        transform: translateX(0);
    }

    .header-sidebar-toggle {
        display: fixed;
    }

}

@media (max-width: 768px) {
    .header-sidebar {
        width: 200px;
        height: 100dvh;
    }

    .header-nav-item {
        padding: 0.75rem 1rem;
    }

    .header-filter-count-span {
        font-size: 0.6rem;
    }
}