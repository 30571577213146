.pcs-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 1000;
}

.pcs-modal {
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
    padding: 1.5rem;
    max-width: 28rem;
    width: 100%;
    box-shadow: var(--card-shadow);
    max-height: 90vh;
    overflow-y: auto;
}

.pcs-space-y-4 > * + * {
    margin-top: 1rem;
}

.pcs-modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    color: var(--text-primary);
}

.pcs-card {
    background-color: var(--background-primary);
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: var(--card-shadow);
}

.pcs-flex-center {
    display: flex;
    justify-content: center;
}

.pcs-card-text {
    text-align: center;
    margin-top: 1rem;
}

.pcs-url-text {
    font-size: 0.875rem;
    color: var(--text-secondary);
}

.pcs-message-text {
    margin-top: 0.5rem;
    color: var(--text-primary);
}

.pcs-input-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.pcs-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--input-border);
    border-radius: 0.375rem;
    background-color: var(--input-background);
    color: var(--text-primary);
}

.pcs-input:focus {
    outline: none;
    border-color: var(--button-background);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.pcs-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1.5rem;
}

.pcs-cancel-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: var(--text-secondary);
    background: none;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: color 0.2s;
}

.pcs-cancel-button:hover {
    color: var(--text-primary);
}

.pcs-download-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: var(--button-text);
    background-color: var(--button-background);
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: opacity 0.2s;
}

.pcs-download-button:hover {
    opacity: 0.9;
}

.pcs-download-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}