.theme-switcher {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.5rem;
}

.theme-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background-color: var(--background-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    transition: all 0.2s ease;
}

.theme-button:hover {
    background-color: var(--background-primary);
    transform: translateY(-1px);
}

.theme-button.active {
    color: var(--background-secondary);
    background: var(--button-background);
}

.theme-section {
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.theme-section h3 {
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
    font-weight: 500;
}