.gs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color: var(--background-secondary);
}

.gs-content {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 2.5rem;
    background-color: var(--background-primary);
    border-radius: 1.5rem;
    padding: 2rem;
    box-shadow: var(--card-shadow);
    width: 100%;
    max-width: 900px;
    margin: 0 2rem;
}

/* Left Side - Branding & Benefits */
.gs-left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gs-logo-container {
    text-align: center;
    margin-bottom: 1.5rem;
}

.gs-logo {
    max-width: 70px;
    height: auto;
    border-radius: 0.5rem;
}

.gs-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
}

.gs-subtitle {
    font-size: 1rem;
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.gs-benefits {
    background-color: var(--background-secondary);
    border-radius: 0.75rem;
    padding: 1.5rem;
}

.gs-benefits h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--text-primary);
}

.gs-benefits ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.gs-benefits li {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    color: var(--text-secondary);
    font-size: 0.95rem;
}

.gs-benefits li:last-child {
    margin-bottom: 0;
}

.gs-benefits li svg {
    color: var(--button-background);
    margin-right: 0.5rem;
    font-size: 1.1rem;
    flex-shrink: 0;
}

/* Divider */
.gs-divider {
    width: 1px;
    background-color: var(--border-color);
    height: 100%;
}

/* Right Side - Sign In Options */
.gs-right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
}

.gs-signin-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    max-width: 350px;
    margin: 0 auto;
    position: relative;
}

.google-button-placeholder {
    width: 85%;
    height: 40px;
    background-color: var(--background-secondary);
    border-radius: 24px;
    display: none;
    /* Hidden by default */
}

/* Show placeholder only when Google button is not loaded */
.gs-signin-buttons:empty .google-button-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 1.5s infinite;
}

.github-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 10px 20px;
    border-radius: 24px;
    border: none;
    background-color: var(--neutral-800);
    color: var(--primary-100);
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    width: 85%;
    max-width: 280px;
    transition: background-color 0.2s ease;
    height: 40px;
}

.github-login-button:hover {
    background-color: var(--neutral-700);
}

.github-icon {
    font-size: 1.1rem;
}

.gs-terms {
    font-size: 0.8rem;
    text-align: center;
    color: var(--text-secondary);
    margin-top: 1.5rem;
}

.gs-terms a {
    color: var(--info);
    text-decoration: none;
}

.gs-terms a:hover {
    text-decoration: underline;
}

.gs-error-container {
    background-color: var(--error);
    color: var(--primary-100);
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 280px;
    margin: 0 auto 1rem auto;
}

.gs-error-message {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
}

.gs-error-button {
    margin-top: 0.75rem;
    color: var(--primary-100);
    text-decoration: underline;
    font-size: 0.9rem;
}

.email-subscription-container {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.email-subscription-label {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    cursor: pointer;
}

.email-subscription-label input {
    margin-right: 0.2rem;
    accent-color: var(--button-background);
}

.gcb-container {
    background-color: var(--background-primary);
    color: var(--text-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.gcb-message-box,
.gcb-error-box {
    background-color: var(--card-background);
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    padding: 20px;
    max-width: 400px;
    width: 100%;
}

.gcb-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 10px;
    text-align: center;
}

.gcb-message,
.gcb-error-message {
    font-size: 1rem;
    color: var(--text-secondary);
    text-align: center;
}

.gcb-error-box {
    border: 1px solid var(--border-color);
}

.gcb-error-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 10px;
    text-align: center;
}

.gcb-message-box .gcb-title {
    color: var(--button-background);
}

.gcb-error-box .gcb-error-title {
    color: var(--header-active-button);
}


@media (max-width: 600px) {
    .gcb-container {
        padding: 10px;
    }

    .gcb-message-box,
    .gcb-error-box {
        padding: 15px;
    }
}


@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.6;
    }
}

/* Add these to the existing CSS file */
.quick-join-input-container {
    width: 100%;
    position: relative;
}

.quick-join-error-message {
    color: var(--header-active-button);
    font-size: 0.7rem;
    margin-top: 0.25rem;
    text-align: left;
    width: 100%;
    padding-left: 10px;
}

.quick-join-input.input-error {
    border-color: var(--header-active-button);
}

.quick-join-input.input-error:focus {
    box-shadow: 0 0 0 3px rgba(var(--header-active-button-rgb), 0.1);
}

.quick-join-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    max-width: 320px;
    margin: 0 auto;
    gap: 1rem;
}

.quick-join-input {
    width: 100%;
    padding: 12px 15px;
    border-radius: 12px;
    border: 1.5px solid var(--border-color);
    font-size: 0.95rem;
    color: var(--text-primary);
    background-color: var(--background-secondary);
    transition:
        border-color 0.3s ease,
        box-shadow 0.3s ease;
    outline: none;
}

.quick-join-input:focus {
    border-color: var(--button-background);
    box-shadow: 0 0 0 2px var(--button-background);
}

.quick-join-input::placeholder {
    color: var(--text-secondary);
    opacity: 0.7;
}

.quick-join-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 12px 20px;
    border-radius: 12px;
    border: none;
    background-color: var(--button-background);
    color: var(--button-text);
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    max-width: 320px;
    transition: background-color 0.2s ease;
    height: 48px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quick-join-button:hover {
    background-color: var(--background-secondary);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.quick-join-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quick-join-note {
    font-size: 0.7rem;
    color: #7ed321; 
    text-align: center;
    line-height: 1.4;
    font-style: italic;
    padding: 0 10px;
  }  

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .gs-content {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 1rem;
        padding: 1.5rem;
    }

    .gs-divider {
        width: 100%;
        height: 1px;
        margin: 0;
    }

    .gs-right-side {
        padding: 0;
    }

    .gs-benefits {
        padding: 1.25rem;
    }

    .gs-signin-buttons {
        margin-top: 0;
    }
}

/* Additional mobile adjustments */
@media (max-width: 480px) {
    .gs-content {
        margin: 0.5rem;
        padding: 1.25rem;
    }

    .gs-title {
        font-size: 1.5rem;
    }

    .gs-subtitle {
        font-size: 0.9rem;
    }
}