/* UserListPopup.css */
.user-list-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.user-list-popup {
    background-color: var(--background-primary);
    border-radius: 8px;
    width: 320px;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    box-shadow: var(--card-shadow);
}

.user-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.user-list-header h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
}

.user-list-close-button {
    padding: 0.25rem;
    border: none;
    background: none;
    border-radius: 9999px;
    cursor: pointer;
    color: var(--text-secondary);
    transition: background-color 0.2s;
}

.user-list-close-button:hover {
    background-color: var(--background-secondary);
}

.user-list-content {
    overflow-y: auto;
    flex: 1;
    padding: 0.5rem;
}

.user-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.user-list-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    text-decoration: none;
    color: var(--text-primary);
    border-radius: 6px;
    transition: background-color 0.2s;
    text-align: start;
}

.user-list-item:hover {
    background-color: var(--background-secondary);
}

.user-list-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-list-info {
    display: flex;
    flex-direction: column;
}

.user-list-name {
    font-weight: 500;
    font-size: 0.95rem;
}

.user-list-username {
    font-size: 0.875rem;
    color: var(--text-secondary);
}

.user-list-empty {
    text-align: center;
    color: var(--text-secondary);
    padding: 2rem 1rem;
    font-size: 0.95rem;
}

.user-list-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
}

.user-list-loading p {
    color: var(--text-secondary);
    margin: 0;
}