.message-display {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    padding: 0.2rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.1rem;
    z-index: 99999;
    display: inline-block;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.message-display.success {
    background-color: var(--neutral-500);
    border-left: 5px solid var(--success);
    color: var(--text-primary);
}

.message-display.error {
    background-color: var(--neutral-500);
    border-left: 5px solid var(--error);
    color: var(--text-primary);
}

.message-display.info {
    background-color: var(--neutral-500);
    border-left: 5px solid var(--info);
    color: var(--text-primary);
}

@media (max-width: 768px) {
    .message-display {
        top: 10px;
    }
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .message-display {
        font-size: 0.875rem;
    }
}