.cip-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2005;
    padding: 1rem;
}

.cip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    border-radius: 1rem;
    padding: 1.5rem;
    max-width: 64rem;
    width: 100%;
    height: 85vh;
    max-height: 700px;
}

.cip-header {
    text-align: center;
    margin-bottom: 1rem;
    flex-shrink: 0;
}

.cip-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.cip-subtitle {
    color: var(--text-secondary);
    font-size: 0.875rem;
}

.cip-image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    flex: 1;
    overflow: hidden;
    height: calc(100% - 140px);
}

.cip-image-wrapper {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease;
    display: flex;
    height: 100%;
    /* Ensure full height of grid row */
    overflow: hidden;
    /* Prevent any overflow */
}


.cip-image-wrapper:hover {
    transform: scale(0.98);
}

.cip-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* Full height of wrapper */
    border-radius: 0.75rem;
    overflow: hidden;
    background-color: var(--background-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cip-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    background-color: var(--background-secondary);
}

.cip-heart-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    animation: heart-pop 0.8s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

.cip-heart {
    color: #ff4b4b;
    width: 4rem;
    height: 4rem;
    filter: drop-shadow(0 0 8px rgba(255, 75, 75, 0.5));
}

@keyframes heart-pop {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }

    50% {
        transform: translate(-50%, -50%) scale(1.4);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

.cip-footer {
    margin-top: 1rem;
    text-align: center;
    flex-shrink: 0;
    padding: 0.5rem;
}

.cip-footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.cip-progress {
    font-size: 0.875rem;
    color: var(--text-secondary);
    font-weight: 500;
}

.cip-skip-button {
    background: none;
    border: none;
    color: var(--text-secondary);
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 0.5rem;
}

.cip-skip-button:hover {
    color: var(--text-primary);
    background-color: var(--background-secondary);
}

@media (max-width: 768px) {

    .cip-image-wrapper {
        max-height: 300px;
    }

    .cip-container {
        height: fit-content;
        padding: 1rem 0.5rem;
        margin: 0;
        border-radius: 0.75rem;
    }

    .cip-image-grid {
        gap: 0.1rem;
        height: calc(100% - 120px);
    }

    .cip-title {
        font-size: 1.25rem;
    }

    .cip-subtitle {
        font-size: 0.813rem;
    }

    .cip-heart {
        width: 3rem;
        height: 3rem;
    }
}