.profile-profile-container {
  padding: 0.2rem 0.2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.profile-profile-card {
  background-color: var(--card-background);
  box-shadow: var(--card-shadow);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.profile-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
}

.profile-actions-settings-refresh {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.profile-social-and-setting-ref-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.profile-visibility-toggle {
  display: flex;
  gap: 1rem;
}

.profile-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
}

.profile-icon-button:hover {
  background-color: var(--background-secondary);
}

.profile-icon-button.delete:hover {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--error);
}

.profile-profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 640px) {
  .profile-profile-header {
    flex-direction: row;
    align-items: flex-start;
  }
}

.profile-profile-image {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  box-shadow: var(--card-shadow);
  position: relative;
}

.profile-profile-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-top: 15px;
  width: 100%;
  gap: 0.5rem;
}

@media (min-width: 640px) {
  .profile-profile-info {
    text-align: left;
    margin-left: 1.5rem;
    margin-top: 0;
  }
}

.profile-profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-primary);
}

.profile-profile-email {
  color: var(--text-secondary);
}

.profile-profile-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.profile-edit-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-social-links-edit input,
.profile-edit-form input,
.profile-edit-form textarea {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-right: 5px;
  background-color: var(--input-background);
  color: var(--text-primary);
}

.profile-edit-form textarea {
  resize: none;
}

.profile-private-profile-message {
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

.profile-album-section {
  margin-top: 2rem;
}

.profile-image-grid {
  column-count: 1;
  column-gap: 1rem;
}

@media (max-width: 768px) {
  .profile-image-overlay button {
    pointer-events: none;
  }

  .profile-image-overlay.visible button {
    pointer-events: auto;
  }

  .profile-image-overlay.visible {
    opacity: 1;
  }

  .profile-icon-button {
    padding: 0.2rem;
  }
}

/* Responsive column count */
@media (min-width: 640px) {
  .profile-image-grid {
    column-count: 2;
  }
}

@media (min-width: 768px) {
  .profile-image-grid {
    column-count: 3;
  }
}

@media (min-width: 1024px) {
  .profile-image-grid {
    column-count: 4;
  }
}

@media (min-width: 1280px) {
  .profile-image-grid {
    column-count: 4;
  }
}

.profile-no-images-message {
  color: var(--text-secondary);
  margin-left: 4px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.profile-logout-button,
.profile-delete-account-button,
.profile-contact-link {
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  text-align: center;
  transition: background-color 0.3s;
}

.delete-account-button {
  display: flex;
  gap: 1rem;
}

.profile-logout-button {
  background-color: var(--background-secondary);
  color: var(--text-primary);
}

.profile-logout-button:hover {
  background-color: var(--neutral-400);
}

.profile-delete-account-button {
  background-color: var(--error);
  color: var(--primary-100);
}

.profile-delete-account-button:hover:not(:disabled) {
  background-color: var(--error);
  opacity: 0.8;
}

.profile-delete-account-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.profile-name-edit,
.profile-username-edit,
.profile-bio-edit {
  display: flex;
  gap: 0.2rem;
}

.profile-bio-edit textarea {
  width: 30%;
  margin: 0.3rem 0 0 0;
  padding: 0.2rem;
  border: 1px solid var(--border-color);
  border-radius: 0.3rem;
  transition: all 0.3s;
  box-shadow: var(--card-shadow);
  height: 60px;
  background-color: var(--input-background);
  color: var(--text-primary);
}

.profile-bio-edit textarea:focus {
  outline: none;
  border-color: var(--text-primary);
  box-shadow: 0 0 0 2px var(--text-primary);
}

.profile-name-edit-buttons,
.profile-username-edit-buttons {
  display: flex;
  gap: 0.2rem;
}

.profile-edit-buttons {
  display: flex;
  margin: 0.3rem 0 0 0;
  gap: 0.3rem;
}

.profile-edit-buttons button {
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 0.3rem;
  width: 60px;
  height: fit-content;
  padding: 0.27rem;
}

.profile-seperator-line {
  width: 100%;
  height: 1px;
  background: var(--border-color);
  margin: 0.5rem 0;
}

.profile-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.profile-stat-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
}

.profile-stat-button.profile-stat-likes {
  cursor: default;
}

.profile-stat-button span {
  margin-left: 0.5rem;
}

.profile-follow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--button-background);
  color: var(--button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-follow-button.following {
  background-color: var(--neutral-400);
}

.profile-follow-button span {
  margin-left: 0.5rem;
}

.followers-list,
.following-list {
  margin-top: 20px;
  background-color: var(--background-secondary);
  border-radius: 8px;
  padding: 15px;
  max-height: 150px;
  overflow-y: auto;
}

.follower-item,
.following-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--text-primary);
}

.follower-item img,
.following-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.follower-image,
.following-image {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  width: 30px;
}

.profile-album-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.profile-toggle-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--button-background);
  color: var(--button-text);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.profile-toggle-button:hover {
  background: var(--text-primary);
}

.profile-album-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
}

@media (max-width: 786px) {
  .profile-bio-edit textarea {
    width: 100%;
  }

  .profile-profile-name,
  .profile-bio {
    justify-content: center;
    margin-left: 0.4rem;
    gap: 0;
    flex-direction: column-reverse;
  }

  .profile-image-grid {
    column-count: 2;
  }
}

.profile-image-info {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 0.8em;
}

/* pfp badge */
.profile-image-container.social-ambassador::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(45deg, var(--warning) 0%, var(--success) 99%, var(--success) 100%);
  border-radius: 50%;
  animation: rotate 10s linear infinite;
}

.profile-image-container.social-ambassador::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: var(--background-primary);
  border-radius: 50%;
}

.profile-image-container.social-ambassador svg {
  fill: var(--success);
  stroke: var(--success);
}

.badge-icon {
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: var(--warning);
  color: var(--text-primary);
  padding: 2px 4px;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-image-container {
  position: relative;
  width: 8.5rem;
  height: 8rem;
  border-radius: 50%;
  overflow: visible;
  z-index: 1;
}

.profile-image-container::before,
.profile-image-container::after {
  z-index: 2;
}

.profile-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  z-index: 3;
}

.badge-icon {
  z-index: 4;
}

@supports (-webkit-touch-callout: none) {
  .profile-image-container {
    contain: paint;
  }
}

/* Skeleton loading styles */
.profile-skeleton {
  background: var(--neutral-300);
  background: linear-gradient(90deg, var(--neutral-200) 25%, var(--neutral-300) 50%, var(--neutral-200) 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.profile-loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.profile-skeleton-profile-image {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.profile-skeleton-text {
  height: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.profile-skeleton-name {
  width: 60%;
  height: 1.5rem;
}

.profile-skeleton-username {
  width: 40%;
}

.profile-skeleton-email {
  width: 70%;
}

.profile-skeleton-bio {
  width: 80%;
  height: 3rem;
}

.profile-skeleton-button {
  width: 8rem;
  height: 2rem;
  border-radius: 0.375rem;
}

.profile-skeleton-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.adult-content-warning {
  display: flex;
  flex-direction: row;
  color: var(--error);
}

.profile-nav-item-container {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-nav-notification-dot {
  position: absolute;
  top: 6px;
  right: 9px;
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  animation: pulse2 1s infinite;
}

.profile-profile-container.liked-images-view .ic-action-button.ic-delete,
.profile-profile-container.liked-images-view .ic-action-button[title="Set as Profile Picture"],
.profile-profile-container.liked-images-view .ic-status-badge {
  display: none;
}

/* Container for the tabs */
.profile-tabs-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

/* Tabs wrapper */
.profile-tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 8px;
}

/* Individual tab */
.profile-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-primary);
  color: var(--text-primary);
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  box-shadow: var(--card-shadow);
}

/* Tab hover effect */
.profile-tab:hover {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
  border-color: var(--border-color);
}

/* Disabled tab */
.profile-tab:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Active tab */
.profile-tab-active {
  background-color: var(--button-background);
  color: var(--button-text);
  border-color: var(--header-active-button);
}

/* Icon styling */
.profile-tab-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.loading-more-indicator{
  width: fit-content;
  margin: 2rem auto;
}