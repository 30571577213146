.home-home-container {
    padding: 0.5rem 0.2rem;
}

.home-feed-container {
    column-count: 2;
    column-gap: 1rem;
}

.home-image-prevent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 10;
}

.home-filter-button {
    display: flex;
    background: var(--button-background);
    color: var(--button-text);
    border: 0;
    outline: none;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
}

.home-top-bar {
    width: 80%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    max-width: 550px;
    display: flex;
    padding: 0.2rem;
    gap: 1rem;
    z-index: 100;
}

@media (max-width: 768px) {

    .home-filter-button {
        gap: 0.2rem;
        padding: 0.3rem 0.5rem;
        border-radius: 0.2rem;
    }

    .home-top-bar {
        width: 75%;
        top: 1rem;
        right: 0.2rem;
        max-width: 550px;
        display: flex;
        padding: 0.1rem;
        gap: 0.4rem;
        z-index: 100;
    }

    .home-view-buttons {
        position: fixed;
        width: calc(100% + 2px);
        z-index: 997;
        top: -1px;
        left: -1px;
        margin: 0;
        border-bottom: 0 !important;
    }

    .home-view-buttons button {
        padding: 12px 0;
    }

    .home-home-container .my-masonry-grid_column {
        margin-top: 5px;
    }

    .my-masonry-grid {
        margin-left: -20px;
    }

    .my-masonry-grid_column {
        padding-left: 10px;
    }
}

.home-error-text {
    text-align: center;
    color: var(--error);
}

@media (max-width: 700px) {
    .home-home-container {
        padding: 0rem 0.2rem 1rem 0.2rem;
        margin-top: -100px;
    }

    .home-image-card {
        width: calc(100vw + 2px);
        margin-right: -20px;
        margin: 0;
        border-radius: 0;
    }

    .home-home-container {
        max-width: 100%;
    }

    .my-masonry-grid {
        margin-left: -20px;
    }

    .my-masonry-grid_column {
        padding-left: 10px;
    }
}

.my-masonry-grid {
    display: flex;
    margin-left: -4px !important;
    width: 100%;
}

.ic-image-card {
    margin-bottom: 4px !important;
    border-radius: 4px !important;
}

.my-masonry-grid_column {
    padding-left: 4px !important;
    background-clip: padding-box;
}

.flyer-component {
    break-inside: avoid;
    margin-bottom: 20px;
}

/* Skeleton loading styles */
.home-skeleton {
    background-color: var(--background-secondary);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.home-skeleton-image {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--neutral-300);
    margin-bottom: 4px;
    border-radius: 4px;
}


@keyframes shimmer {
    0% {
        background-position: -2000px 0;
    }

    100% {
        background-position: 2000px 0;
    }
}

@media (max-width: 768px) {
    .home-skeleton {
        margin-bottom: 4px;
    }

    .home-skeleton-image {
        aspect-ratio: 1 / 1;
    }
}

.home-skeleton-image {
    animation: pulse 2s infinite linear;
    background-color: var(--neutral-300);
}

.home-no-more-images {
    text-align: center;
    color: var(--text-secondary);
    margin-top: 20px;
    font-style: italic;
}

.home-end-content {
    text-align: center;
    padding: 40px 0;
    border-radius: 8px;
    margin-top: 40px;
    border-top: 1px solid var(--border-color);
}

.home-no-more-images {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin-bottom: 20px;
}

.home-end-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.home-end-button {
    padding: 12px 24px;
    font-size: 1rem;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--button-background);
    color: var(--button-text);
}

.home-end-button:hover {
    background-color: var(--text-primary);
}

@media (max-width: 576px) {
    .home-end-buttons {
        flex-direction: column;
        align-items: center;
    }

    .home-end-button {
        width: 80%;
    }
}

.home-content {
    display: flex;
    width: 100%;
}

.home-masonry-container {
    flex: 1;
}

@media (max-width: 768px) {
    .home-content {
        flex-direction: column;
    }

}