.ri-reported-images {
    padding: 20px;
}

.ri-reported-images h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.ri-image-card {
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.ri-report-info {
    background-color: #f8f8f8;
    padding: 10px;
    border-top: 1px solid #eaeaea;
}

.ri-report-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
}

.ri-loading {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin-top: 20px;
}

.ri-error {
    color: #ff4d4d;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}

.my-masonry-grid {
    display: flex;
    margin-left: -20px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 20px;
    background-clip: padding-box;
}