.ms-selector {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 1rem;
}

.ms-selector-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.ms-model-card {
    position: relative;
    border: 2px solid var(--border-color);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.4s ease;
    background-color: var(--background-secondary);
    min-width: 100px;
    height: 150px; /* 1080/5 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ms-model-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 5%, rgba(0,0,0,1) 100%);
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 1;
}

.ms-model-card:hover::before {
    opacity: 1;
}

.ms-model-card:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.ms-model-card.ms-active {
    border-color: var(--text-primary);
    box-shadow: 0 0 0 3px var(--text-primary);
}

.ms-model-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ms-model-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
}

.ms-model-card:hover .ms-model-image {
    transform: scale(1.1);
}

.ms-model-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.75rem;
    color: #fff;
    z-index: 2;
    opacity: 1;
    transition: all 0.4s ease;
}

.ms-model-name {
    margin: 0 0 0.25rem 0;
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
}

.ms-model-description {
    margin: 0;
    font-size: 0.8rem;
    color: #fff;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease;
}

.ms-model-card:hover .ms-model-description {
    max-height: 3rem;
    opacity: 1;
    margin-top: 0.5rem;
}

@media (max-width: 1200px) {
    .ms-selector-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media (max-width: 786px) {
    .ms-selector-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
