:root {
    --hover-scale: 1.2;
    --hover-rotation: 10deg;
    --transition-speed: 0.3s;
}

.interactive-text {
    display: inline-flex;
    font-size: 3.55rem;
    font-weight: bold;
    user-select: none;
}

.interactive-text .char {
    display: inline-block;
    transition:
        transform var(--transition-speed) ease-out,
        color var(--transition-speed) ease-out,
        text-shadow var(--transition-speed) ease-out;
    position: relative;
    transform-origin: center;
    margin: 0 2px;
}

.interactive-text .char:hover {
    transform:
        scale(var(--hover-scale)) rotate(calc(var(--hover-rotation) * var(--direction)));
    z-index: 10;
}

/* Neighbor effects */
.interactive-text .char:hover~.char {
    transform: translateX(10px) scale(1.05);
}

.interactive-text .char:hover+.char {
    transform: translateX(15px) scale(1.1);
}

/* Background image clip effect */
.interactive-text .char {
    background-image: url('../../public/images/Rangrez-AI-bg.jpg');
    background-size: 200%;
    background-position: 0% 50%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    animation: moveBackground 10s linear infinite;
}

.interactive-text .char:nth-child(2n) {
    animation: moveBackground 5s linear infinite;
}

.interactive-text .char:nth-child(3n) {
    animation: moveBackground 7s linear infinite;
}

/* Background movement animation */
@keyframes moveBackground {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Hover color and shadow effects */
.interactive-text .char:nth-child(odd):hover {
    text-shadow:
        3px 3px 5px rgba(0, 0, 0, 0.15),
        6px 6px 10px rgba(0, 0, 0, 0.1),
        10px 10px 15px rgba(0, 0, 0, 0.05);
}

.interactive-text .char:nth-child(even):hover {
    text-shadow:
        -3px -3px 5px rgba(0, 0, 0, 0.15),
        -6px -6px 10px rgba(0, 0, 0, 0.1),
        -10px -10px 15px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .interactive-text {
        font-size: 2.5rem;
    }

    .interactive-text .char:hover~.char {
        transform: translateX(5px) scale(1.02);
    }

    .interactive-text .char:hover+.char {
        transform: translateX(8px) scale(1.05);
    }
}