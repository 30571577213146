.set-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.set-field-group {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.set-label {
    min-width: 8rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-primary);
}

.set-select {
    flex: 1;
    padding: 0.5rem;
    border-radius: 0.375rem;
    border: 1px solid var(--border-color);
    background-color: var(--background-primary);
    color: var(--text-primary);
    cursor: pointer;
}

.set-select:hover {
    border-color: var(--border-hover);
}

.set-button {
    flex: 1;
    padding: 0.5rem;
    text-align: left;
    border-radius: 0.375rem;
    border: 1px solid var(--border-color);
    background-color: var(--background-primary);
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
}

.set-button:hover {
    background-color: var(--background-secondary);
    border-color: var(--border-hover);
}

.set-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s;
}

.set-overlay.active {
    opacity: 1;
    visibility: visible;
}

.set-selector {
    position: absolute;
    background-color: var(--background-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.75rem;
    box-shadow: var(--shadow-lg);
    padding: 1rem;
    width: auto !important;
    max-width: 25%;
    top: 50%;
    transform: translateY(-50%) scale(0.9);
    opacity: 0;
    z-index: 50;
    right: 0;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.set-selector.active {
    transform: translateY(-50%) scale(1);
    opacity: 1;
}


.set-selector>div:last-child {
    max-height: 60vh;
    overflow-y: auto;
}

@media (max-width: 760px) {
    .set-selector {
        position: fixed;
        left: 1rem !important;
        right: 1rem !important;
        top: 50% !important;
        max-width: 100%;
    }
}

.set-selector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.set-selector-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
}

.set-close-button {
    padding: 0.25rem;
    border-radius: 9999px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--text-secondary);
}

.set-close-button:hover {
    background-color: var(--background-secondary);
}

.set-options-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.set-option {
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    border: none;
    background-color: var(--background-secondary);
    color: var(--text-primary);
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: capitalize;
}

.set-option:hover {
    background-color: var(--background-secondary-hover);
    transform: translateY(-1px);
}

.set-option.active {
    background-color: var(--button-background);
    color: var(--button-text);
}