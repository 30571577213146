.nt-notifications-tab {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--background-primary);
    border-radius: 12px;
    box-shadow: var(--card-shadow);
}

.nt-notifications-tab h2 {
    font-size: 1.8rem;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    text-align: center;
}

.nt-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.nt-input,
.nt-textarea,
.nt-select {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 1rem;
    background-color: var(--input-background);
    color: var(--text-primary);
}

.nt-textarea {
    min-height: 100px;
    resize: vertical;
}

.nt-button {
    padding: 0.8rem 1.5rem;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.nt-button:hover {
    background-color: var(--neutral-800);
}

.nt-button:active {
    transform: translateY(1px);
}

.nt-notifications-list {
    list-style-type: none;
    padding: 0;
}

.nt-notification-item {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: var(--card-shadow);
}

.nt-notification-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-primary);
}

.nt-notification-date{
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
}

.nt-notification-message {
    font-size: 1rem;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
}

.nt-notification-type {
    display: inline-block;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.nt-type-info {
    background-color: var(--info);
    color: var(--neutral-100);
}

.nt-type-warning {
    background-color: var(--warning);
    color: var(--neutral-900);
}

.nt-type-promotion {
    background-color: var(--error);
    color: var(--neutral-100);
}

.nt-type-instagram {
    background-color: var(--primary-brand-color-bright);
    color: var(--neutral-100);
}

.nt-type-contest {
    background-color: var(--primary-brand-color-dark);
    color: var(--neutral-100);
}

.nt-type-community {
    background-color: var(--success);
    color: var(--neutral-100);
}

.nt-action-link {
    display: inline-block;
    margin-right: 1rem;
    color: var(--info);
    text-decoration: none;
    margin-left: 1rem;
}

.nt-action-link:hover {
    text-decoration: underline;
}

.nt-action-buttons {
    display: flex;
    gap: 0.5rem;
}

.nt-resend-button,
.nt-delete-button {
    background-color: var(--error);
    color: var(--neutral-100);
    border: none;
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nt-resend-button {
    background: var(--neutral-900);
}

.nt-delete-button:hover {
    background-color: var(--error);
    opacity: 0.9;
}

.nt-notification-item {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.nt-read-by {
    margin-top: 10px;
    font-size: 0.9em;
    color: var(--text-secondary);
}

.nt-read-by strong {
    display: block;
    margin-bottom: 5px;
    color: var(--text-primary);
}

.nt-read-by-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.nt-read-by-item {
    background-color: var(--background-secondary);
    border-radius: 15px;
    padding: 3px 10px;
    margin: 2px;
    font-size: 0.8em;
    color: var(--text-primary);
}

.nt-read-by-item:hover {
    background-color: var(--neutral-300);
}

@media (max-width: 768px) {
    .nt-notifications-tab {
        padding: 1rem;
    }

    .nt-button {
        width: 100%;
    }
    .nt-notification-item {
        flex-direction: column;
    }
    .nt-action-buttons{
        margin-top: 1rem;
    }
}