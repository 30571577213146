/* AdminDashboard.css */
.ad-admin-dashboard {
    font-family: 'Roboto', Arial, sans-serif;
    max-width: 100%;
    padding: 20px;
    background-color: var(--background-secondary);
    box-shadow: var(--card-shadow);
    border-radius: 8px;
}

.ad-tabs {
    display: flex;
    justify-content: start;
    max-width: 100vw;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    background-color: var(--background-primary);
    padding: 10px;
    border-radius: 5px;
    box-shadow: var(--card-shadow);
}

.ad-tab {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: var(--text-secondary);
    transition: all 0.3s ease;
    border-radius: 5px;
}

.ad-tab:hover {
    background-color: var(--background-secondary);
}

.ad-tab.ad-active {
    background-color: var(--primary-800);
    color: var(--primary-100);
}

.user-management {
    font-family: 'Roboto', Arial, sans-serif;
    max-width: 100%;
    padding: 20px;
    background-color: var(--background-secondary);
    box-shadow: var(--card-shadow);
    border-radius: 8px;
}

.ad-dashboard-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: var(--text-primary);
    text-align: center;
}

.ad-active-users,
.ad-user-stats {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.ad-active-users {
    justify-content: space-around;
}

.ad-active-users,
.ad-user-count {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    color: var(--text-secondary);
}

.ad-search-bar {
    margin-bottom: 20px;
    display: flex;
    gap: 5px;
}

.ad-search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--input-background);
    color: var(--text-primary);
}

.ad-search-bar button{
    background: var(--button-background);
    color: var(--button-text);
    padding: 0.2rem 0.4rem;
}

.ad-content {
    background-color: var(--background-primary);
    padding: 20px;
    border-radius: 5px;
    box-shadow: var(--card-shadow);
    overflow-x: auto;
}

.ad-loading,
.ad-error,
.ad-no-data {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    padding: 20px;
    background-color: var(--background-secondary);
    border-radius: 5px;
}

.ad-error {
    color: var(--error);
}

.ad-table-container {
    overflow-x: auto;
    max-width: 100%;
}

.ad-column-toggles {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.ad-column-toggle {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: var(--text-primary);
}

.ad-column-toggle input {
    margin-right: 5px;
}

.ad-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    white-space: nowrap;
}

.ad-table th,
.ad-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ad-table th {
    background-color: var(--primary-brand-color-bright);
    color: var(--primary-100);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: sticky;
    top: 0;
    z-index: 10;
}

.ad-table th:hover {
    background-color: var(--primary-brand-color-dark);
}

.ad-table tr:nth-child(even) {
    background-color: var(--background-secondary);
}

.ad-table tr:hover {
    background-color: var(--background-secondary);
}

@media (max-width: 768px) {
    .ad-table {
        font-size: 14px;
    }

    .ad-table th,
    .ad-table td {
        padding: 8px;
    }
}