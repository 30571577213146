/* HomeSearch.css */
.home-search-container {
    width: 100%;
    z-index: 100;
}

.home-search-input {
    width: 100%;
    padding: 12px 35px 12px 15px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    background-color: var(--input-background);
    color: var(--text-primary);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.home-search-input:focus {
    border-color: var(--button-background);
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.home-search-clear {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 5px;
    line-height: 1;
}

.home-search-clear:hover {
    color: var(--text-primary);
}

/* Add these to the existing HomeSearch.css file */
.home-search-input-wrapper {
    position: relative;
    width: 100%;
}

.home-search-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    z-index: 100;
}

.home-suggested-tags {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border-top: 1px solid var(--border-color);
}

.home-tag {
    cursor: pointer;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.home-trending-tag {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: var(--background-secondary-800);
    color: var(--text-primary);
}
.home-trending-tag:hover .home-trending-arrow{
    color: var(--button-text);
}
.home-trending-tag:hover {
    background-color: var(--button-background);
    color: var(--button-text);
}

.home-trending-arrow {
    font-weight: bold;
    color: var(--button-background);
    margin-right: 2px;
}