/* AdultContentWarning.css */
.acw-fixed {
    position: fixed;
}

.acw-inset-0 {
    inset: 0;
}

.acw-bg-red-500\/90 {
    background-color: rgba(239, 68, 68, 0.4);
}

.acw-backdrop-blur-sm {
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
}

.acw-z-50 {
    z-index: 50;
}

.acw-flex {
    display: flex;
}

.acw-items-center {
    align-items: center;
}

.acw-justify-center {
    justify-content: center;
}

.acw-p-4 {
    padding: 1rem;
}

.acw-bg-white {
    background-color: var(--background-primary);
}

.acw-rounded-lg {
    border-radius: 0.5rem;
}

.acw-p-6 {
    padding: 1.5rem;
}

.acw-max-w-md {
    max-width: 28rem;
}

.acw-text-center {
    text-align: center;
}

.acw-shadow-xl {
    box-shadow: var(--card-shadow);
}

.acw-w-16 {
    width: 4rem;
}

.acw-h-16 {
    height: 4rem;
}

.acw-text-red-500 {
    color: var(--error);
}

.acw-mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.acw-mb-4 {
    margin-bottom: 1rem;
}

.acw-text-2xl {
    font-size: 1.5rem;
}

.acw-font-bold {
    font-weight: bold;
}

.acw-text-gray-900 {
    color: var(--text-primary);
}

.acw-mb-6 {
    margin-bottom: 1.5rem;
}

.acw-text-gray-600 {
    color: var(--text-secondary);
}

.acw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.acw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.acw-bg-red-500 {
    background-color: var(--error);
}

.acw-text-white {
    color: var(--primary-100);
}

.acw-hover\:bg-red-600:hover {
    background-color: var(--error);
    opacity: 0.9;
}

.acw-transition-colors {
    transition: background-color 0.3s;
}

.acw-fixed button {
    border: none;
}