/* TermsOfService.css */
.tos-container {
    max-width: 800px;
    padding: 2rem;
    margin: 0 auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tos-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: var(--text-primary);
    text-align: center;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 1rem;
}

.tos-content {
    line-height: 1.7;
    color: var(--text-primary);
}

.tos-section {
    margin-bottom: 2.5rem;
    padding: 1.5rem;
    background-color: var(--background-primary);
    border-radius: 0.75rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tos-section:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tos-section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.tos-text {
    margin-bottom: 1rem;
    color: var(--text-secondary);
    font-size: 1rem;
}

.tos-list {
    list-style-type: none;
    margin: 1rem 0;
    padding-left: 1.5rem;
}

.tos-list li {
    position: relative;
    padding: 0.5rem 0;
    padding-left: 1.5rem;
    color: var(--text-secondary);
}

.tos-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--accent-color);
}

/* Important sections highlighting */
.tos-section:nth-child(4),
/* Prohibited Content */
.tos-section:nth-child(7)

/* Content Moderation */
    {
    border-left: 4px solid var(--accent-color);
    background-color: var(--background-warning);
}

.tos-container a {
    color: var(--accent-color);
    text-decoration: none;
    transition: color 0.2s ease;
}

.tos-container a:hover {
    color: var(--accent-hover);
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .tos-container {
        padding: 1.5rem;
        margin: 1rem;
    }

    .tos-title {
        font-size: 2rem;
    }

    .tos-section-title {
        font-size: 1.25rem;
    }

    .tos-section {
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .tos-container {
        padding: 1rem;
        margin: 0.5rem;
    }

    .tos-title {
        font-size: 1.75rem;
    }
}

/* Print styles */
@media print {
    .tos-container {
        box-shadow: none;
        max-width: 100%;
    }

    .tos-section:hover {
        transform: none;
        box-shadow: none;
    }
}