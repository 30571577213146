.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    min-height: 100vh;
}

.blog-title {
    text-align: center;
    font-size: 2.5rem;
    color: var(--text-primary);
    margin-bottom: 3rem;
    font-weight: 600;
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 3rem;
}

.content-card {
    background: var(--background-secondary);
    border-radius: 12px;
    padding: .5rem;
    box-shadow: 0 4px 6px var(--card-shadow);
    transition: transform 0.2s ease;
}


.card-title {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.card-items {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.card-item {
    overflow: hidden;
    background: var(--background-primary);
    border-radius: 8px;
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.2s ease;
}

.item-header:hover {
    background: var(--background-hover);
    color: var(--text-primary);
}

.chevron {
    transition: transform 0.3s ease;
}

.chevron.open {
    transform: rotate(180deg);
}

.item-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.item-content.open {
    max-height: 70vh;
}

.content-wrapper {
    color: var(--text-secondary);
    border-top: 1px solid var(--border-color);
    overflow-y: auto;
    max-height: 70vh;
}

/* Enhanced Markdown Styles */
.content-wrapper strong,
.content-wrapper b {
    color: var(--text-primary);
    font-weight: 600;
    font-size: 1.05em;
    display: inline-block;
    margin-top: 1.25em;
    margin-bottom: 0.5em;
}

.content-wrapper p {
    margin: 0.75em 0;
    line-height: 1.6;
}

.content-wrapper a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.2s ease;
}

.content-wrapper a:hover {
    color: var(--primary-color-hover);
    text-decoration: underline;
}

.content-wrapper ul,
.content-wrapper ol {
    padding-left: 1.5rem;
    margin: 0.75em 0;
}

.content-wrapper li {
    margin: 0.5em 0;
    line-height: 1.5;
}

.content-wrapper ul li {
    list-style-type: disc;
}

.content-wrapper ul li li {
    list-style-type: circle;
}

/* Custom Scrollbar */
.content-wrapper::-webkit-scrollbar {
    width: 4px;
}

.content-wrapper::-webkit-scrollbar-track {
    background: var(--background-secondary);
    border-radius: 4px;
}

.content-wrapper::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 4px;
    transition: background 0.2s ease;
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
    background: var(--text-secondary);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .blog-container {
        padding: 1rem;
    }

    .blog-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .content-card {
        padding: 1rem;
    }

    .content-wrapper {
        padding: 1rem;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content-card {
    animation: fadeIn 0.4s ease-out;
}

/* Nested FAQ Styles */
.card-item .card-item {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;
}

.card-item .card-item .item-header {
    background: var(--background-primary);
    font-size: 0.95em;
    font-weight: bold;
}

.card-item .card-item .content-wrapper {
    background: var(--background-primary);
    padding: 1rem 2rem;
}

/* Transition for nested accordions */
.card-item .card-item .item-content {
    background: var(--background-primary);
    transition: all 0.3s ease-out;
}