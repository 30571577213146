.fp-container {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-primary);
}

.fp-content {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--background-primary);
    padding: 4rem;
}

.fp-header {
    text-align: center;
    margin-bottom: 0rem;
}

.fp-heading {
    font-size: 2.5rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
}

.fp-subheading {
    font-size: 1.2rem;
    color: var(--text-secondary);
}

.fp-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fp-description p {
    color: var(--text-secondary);
    line-height: 1.6;
    font-size: 1rem;
    text-align: center;
}

.fp-credit {
    background-color: var(--background-secondary);
    border-radius: 12px;
    padding: 2rem;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fp-credit-heading {
    font-size: 1.3rem;
    color: var(--text-primary);
    margin-bottom: 0rem;
    text-align: center;
}

.fp-credit-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0rem;
}

.fp-credit-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-primary);
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}


@media screen and (max-width: 1024px) {
    .fp-content {
        padding: 2rem;
    }

    .fp-header {
        text-align: center;
    }

    .fp-details {
        text-align: center;
    }
}

@media screen and (max-width: 786px) {
    .fp-content {
        flex-direction: column;
        margin: 0;
        width: 100%;
        padding: 1.5rem;
    }

    .fp-description p{
         text-align: start;
    }

    .fp-heading {
        font-size: 2rem;
    }

    .fp-subheading {
        font-size: 1rem;
    }
}