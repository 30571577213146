.icgc-container.igh-container {
    flex-direction: row-reverse;
    overflow: hidden;
    background-color: var(--background-primary);
}

.icgc-container.igh-container .igh-images-container {
    margin-top: 20px;
}

.igh-container .igh-right-section {
    z-index: 10;
}

.igh-container .igh-image-link span {
    position: absolute;
    transform: translate(224%, -76%);
    z-index: 300;
    background: var(--background-primary);
    width: 150px;
    padding: 0.5rem;
    font-size: 0.7rem;
    border-radius: 0.5rem;
    color: var(--text-primary);
}

.igh-container .igh-image-link:first-child span {
    transform: translate(304%, 651%);
}

.igh-container .igh-image-link:last-child span {
    transform: translate(-3%, -54%);
}

@media screen and (max-width: 1024px) {
    .icgc-container.igh-container {
        flex-direction: column;
    }
    .igh-container .igh-image-link span {
        display: none;
    }
}