/* Search.css */
.ser-search-container {
    position: relative;
    max-width: 350px;
    width: 95%;
    z-index: 1000;
}

.ser-search-input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.ser-search-input {
    width: 100%;
    padding: 10px 35px 10px 15px;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    color: var(--text-primary);
    transition: all 0.2s ease;
}

.ser-search-input::placeholder {
    color: var(--text-secondary);
}

.ser-search-input:focus {
    border-color: var(--button-background);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.ser-loading-spinner {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid var(--background-secondary);
    border-top: 2px solid var(--button-background);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    pointer-events: none;
}

@keyframes spin {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        transform: translateY(-50%) rotate(360deg);
    }
}

.ser-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    max-height: 400px;
    overflow-y: auto;
    background: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: var(--card-shadow);
}

.ser-dropdown::-webkit-scrollbar {
    width: 8px;
}

.ser-dropdown::-webkit-scrollbar-track {
    background: var(--background-secondary);
    border-radius: 0 8px 8px 0;
}

.ser-dropdown::-webkit-scrollbar-thumb {
    background: var(--header-active-button);
    border-radius: 4px;
}

.ser-design-item {
    display: flex;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--border-color);
}

.ser-design-item:last-child {
    border-bottom: none;
}

.ser-design-item:hover {
    background-color: var(--background-primary-800);
}

.ser-design-thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 6px;
    background-color: var(--background-secondary);
}

.ser-design-info {
    margin-left: 12px;
    flex: 1;
}

.ser-design-title {
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--text-primary);
}

.ser-design-type {
    font-size: 12px;
    color: var(--text-secondary);
    margin-bottom: 6px;
}

.ser-design-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.ser-tag {
    font-size: 11px;
    padding: 3px 8px;
    background-color: var(--background-secondary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--text-secondary);
}

.ser-no-results {
    padding: 20px;
    text-align: center;
    color: var(--text-secondary);
}

/* Add these to the existing Search.css file */

.ser-suggested-tags {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border-top: 1px solid var(--border-color);
}

.ser-trending-tag {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: var(--background-secondary-800);
    color: var(--text-primary);
    transition: background-color 0.2s ease;
    cursor: pointer;
}
.ser-trending-tag:hover .ser-trending-arrow{
    color: var(--button-text);
}
.ser-trending-tag:hover {
    background-color: var(--button-background);
    color: var(--button-text);
}

.ser-trending-arrow {
    font-weight: bold;
    color: var(--button-background);
    margin-right: 2px;
}