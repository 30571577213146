/* EmailTab.css */
.email-tab {
    background-color: var(--background-primary);
    color: var(--text-primary);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    max-width: 800px;
    margin: 0 auto;
}

.email-tab h2 {
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: var(--text-primary);
}

.email-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    margin-bottom: 24px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.preview-button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.95rem;
    flex-shrink: 0;
}

.preview-button:hover {
    opacity: 0.9;
}

.email-form input[type="text"],
.email-form textarea,
.email-form select {
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.email-form input[type="text"]:focus,
.email-form textarea:focus,
.email-form select:focus {
    outline: 2px solid var(--border-color);
}

.email-form textarea {
    resize: vertical;
}

.email-form button {
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.95rem;
}

.email-form button:disabled {
    background-color: var(--header-active-button);
    color: var(--text-secondary);
    cursor: not-allowed;
}

.email-preview-container {
    background-color: var(--background-secondary);
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    margin-bottom: 24px;
}

.email-preview-container h3 {
    margin-bottom: 12px;
    font-size: 1.25rem;
    color: var(--text-primary);
}

.email-preview {
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 16px;
    max-height: 300px;
    overflow-y: auto;
}

.html-preview {
    color: black;
}

.plain-preview {
    white-space: pre-wrap;
    font-family: monospace;
    color: var(--text-primary);
}

.email-logs {
    background-color: var(--background-secondary);
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
}

.email-logs h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--text-primary);
}

.email-logs table {
    width: 100%;
    border-collapse: collapse;
}

.email-logs th,
.email-logs td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
}

.email-logs th {
    background-color: var(--background-primary-800);
    color: var(--text-primary);
    font-weight: bold;
}

.email-logs td {
    color: var(--text-secondary);
}

.email-logs tr.success td {
    color: var(--text-primary);
}

.email-logs tr.error td {
    color: red;
}

.email-logs tr:hover {
    background-color: var(--header-active-button);
}