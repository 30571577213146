.fv-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-primary);
    padding: 20px;
}

.fv-card {
    background-color: var(--card-background);
    border-radius: 12px;
    box-shadow: var(--card-shadow);
    max-width: 500px;
    width: 100%;
    padding: 30px;
    text-align: center;
}

.fv-card-header {
    margin-bottom: 20px;
    text-align: start;
}

.fv-card-header h2 {
    color: var(--text-primary);
    font-size: 24px;
    margin-bottom: 10px;
}

.fv-card-header p {
    color: var(--text-secondary);
}

.fv-instagram-preview {
    margin-bottom: 20px;
}

.fv-instagram-mockup {
    background-color: var(--background-primary-800);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fv-profile-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.fv-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.fv-profile-details h3 {
    color: var(--text-primary);
    margin: 0;
}

.fv-profile-details p {
    color: var(--text-secondary);
    margin: 5px 0 0;
    font-size: 14px;
}

.fv-follow-button {
    width: 100%;
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 12px 15px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fv-follow-button:hover {
    background-color: var(--header-active-button);
}

.fv-benefits {
    margin-top: 20px;
    text-align: left;
}

.fv-benefits h4 {
    color: var(--text-primary);
    margin-bottom: 10px;
    text-align: center;
}

.fv-benefits ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.fv-benefits li {
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    gap: 10px;
}


.fv-verification-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.fv-handle-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 16px;
}

.fv-submit-button {
    width: 100%;
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 12px 15px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fv-submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.fv-submission-status {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
}

.fv-submission-status.success {
    background-color: rgba(0, 255, 0, 0.05);
    color: green;
}

.fv-submission-status.error {
    background-color: rgba(255, 0, 0, 0.1);
    color: red;
}