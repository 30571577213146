.ic-image-card {
    background-color: var(--card-background);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    break-inside: avoid;
    margin-bottom: 1rem;
    border: 0;
    border-radius: 0.8rem;
    user-select: none;
}

.ic-image-card.ic-image-error {
    display: none;
}

.ic-image-card:hover {
    box-shadow: var(--card-shadow);
}

.ic-image-details {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    position: absolute;
}

.ic-user-info-wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    padding-right: 0.2rem;
}

.ic-user-info {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0.5rem;
    gap: 0.2rem;
    width: 100%;
    z-index: 10;
    max-height: 100%;
    user-select: none;
}

.ic-user-avatar-container {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: visible;
    margin-right: 10px;
}

.ic-user-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}

.ic-user-avatar.ic-loaded {
    opacity: 1;
}

.ic-user-link {
    color: var(--primary-100);
    text-decoration: none;
    overflow: hidden;
    max-width: 50px;
}

.ic-image-wrapper {
    position: relative;
    overflow: hidden;
}

.ic-image {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ic-image.ic-loaded {
    opacity: 1;
}

.ic-image.ic-blurred {
    filter: blur(20px);
}

.ic-image.ic-blurred:hover {
    filter: blur(10px);
}

.ic-image-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.ic-main-image .ic-image-overlay {
    background-color: transparent;
}

.ic-image-placeholder {
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s ease;
    z-index: 80;
}

.ic-image-placeholder.ic-not-loaded {
    pointer-events: none;
    cursor: default;
}

/* Placeholder colors */
.ic-image-placeholder.color-1 {
    background-color: #FADADD;
}

.ic-image-placeholder.color-2 {
    background-color: #CFE0E8;
}

.ic-image-placeholder.color-3 {
    background-color: #F4E1D2;
}

.ic-image-placeholder.color-4 {
    background-color: #D3E4CD;
}

.ic-image-placeholder.color-5 {
    background-color: #F9EAC2;
}

.ic-image-placeholder.color-6 {
    background-color: #F5F5F5;
}

.ic-image-placeholder.color-7 {
    background-color: #E2E8F0;
}

.ic-image-placeholder.color-8 {
    background-color: #E0CFC1;
}

.ic-image.ic-loaded+.ic-image-placeholder {
    opacity: 0;
    pointer-events: none;
}

.ic-hidden-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    background-color: var(--neutral-200);
    color: var(--text-secondary);
    font-size: 14px;
}

.ic-action-button:last-child {
    padding: 0.3rem;
}

.ic-action-icon.ic-image-likes {
    color: var(--primary-100);
}

.ic-user-info-wrapper .ic-action-button.ic-more-button {
    background: var(--background-primary);
    box-shadow: none;
    backdrop-filter: none;
    z-index: 99;
    justify-content: center;
}

.ic-user-info-wrapper .ic-action-button.ic-more-button svg {
    color: var(--text-primary);
}

.ic-remove-watermark {
    width: fit-content;
    margin-left: auto;
    margin-right: 0.5rem;
    padding: 1px 4px;
    border-radius: 0.2rem;
    font-size: 0.7rem;
    margin-bottom: -2rem;
    z-index: 99;
    color: var(--text-primary);

    &:hover {
        text-decoration: none;
        color: var(--button-text);
        background: var(--button-background);
    }
}

.ic-image-likes {
    color: var(--primary-100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -3px;
}

.ic-image-likes.ic-landscape {
    flex-direction: row-reverse;
    margin-bottom: 0px;
    margin-right: -5px;
}

.ic-image-likes svg {
    color: var(--primary-100);
}

.ic-animate-spin {
    animation: ic-spin 1s linear infinite;
}

@keyframes ic-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ic-action-button.ic-delete:hover {
    background-color: rgba(239, 68, 68, 0.2);
}

.ic-action-button.ic-delete:hover .ic-action-icon.ic-delete {
    color: var(--error);
}

.ic-image-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
    position: absolute;
    bottom: 0px;
    right: 0;
    max-height: 100%;
    padding: 0.05rem;
    border-radius: 0.3rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--neutral-700);
}

.ic-image-buttons:hover {
    z-index: 50;
}

.ic-image-buttons.ic-landscape {
    flex-direction: row;
}

.ic-image-title {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: var(--primary-100);
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    max-width: 80%;
    user-select: none;
}

.ic-image-title:hover {
    background: rgba(0, 0, 0, 0.62);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    z-index: 50;
}

.ic-heart-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    color: var(--primary-100);
    font-size: 100px;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
}

.ic-heart-animation.active {
    animation: heartBeat 1s ease-in-out forwards;
}

.ic-user-avatar-container.ic-social-ambassador::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(45deg, var(--warning) 0%, var(--error) 99%, var(--error) 100%);
    border-radius: 50%;
    z-index: 1;
    animation: rotate 10s linear infinite;
}

.ic-user-avatar-container.ic-social-ambassador::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: var(--primary-100);
    border-radius: 50%;
    z-index: 1;
}

.ic-badge-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--warning);
    color: var(--primary-900);
    padding: 2px;
    border-radius: 50%;
    box-shadow: var(--card-shadow);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
}

.ic-badge-icon svg {
    width: 14px;
    height: 14px;
    stroke: var(--success);
    fill: var(--success);
}

.ic-image-reason {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--warning);
    font-size: 0.7rem;
    cursor: default;
}

.ic-edit-button svg {
    stroke: var(--button-text);
}

.ic-edit-button {
    position: absolute;
    background-color: var(--button-background);
    outline: none;
    border: 0;
    bottom: 0rem;
    left: 0rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    cursor: pointer;
    z-index: 99;
}


/* Media Queries */
@media (min-width: 769px) {
    .ic-image-wrapper:hover .ic-image-overlay {
        opacity: 1;
    }

    .ic-image-wrapper.ic-show .ic-image-overlay {
        opacity: 1;
    }

    .ic-image-card {
        cursor: zoom-in;
    }

    .ic-main-image.ic-image-card {
        cursor: default;
    }

    .ic-image-card.ic-tool-image {
        cursor: default;
    }

    .ic-image-buttons:hover {
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
    }

    .ic-more-options {
        max-width: 450px !important;
        margin: auto;
    }

}

@media (max-width: 786px) {

    .ic-edit-button {
        padding: 0.3rem;
        left: -1px;
        bottom: -1px;
        transform: scale(0.95);
    }

    .ic-remove-watermark {
        font-size: 0.6rem;
    }

    .ic-image-buttons {
        border: 0;
    }

    .ic-image-overlay {
        background-color: rgba(0, 0, 0, 0.05);
        opacity: 1;
        pointer-events: auto;
    }

    .ic-user-avatar {
        width: 1.5rem;
        height: 1.5rem;
    }

    .ic-user-link {
        font-size: 0.8rem;
    }

    .ic-image-buttons {
        background: none;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}

.ic-action-button {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.2rem;
    padding: 0.2rem;
    background: none;
    color: var(--primary-100);
    border: none;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
    padding: 0.4rem;
}


.ic-image-card.ic-selected .ic-image-overlay,
.ic-image-overlay.ic-hidden {
    opacity: 0;
    pointer-events: none;
}

.ic-more-options {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-secondary);
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    max-height: 80vh;
    width: calc(100% + 1px);
    z-index: 1030;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ic-more-options.ic-show {
    max-width: calc(100% + 1px);
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    z-index: 1002;
    padding-bottom: 10px;
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
}

.ic-more-options::-webkit-scrollbar {
    display: none;
}

.ic-more-options-backdrop {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1001;
    cursor: default;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.ic-more-options .ic-action-button {
    padding: 0.75rem;
    width: 100%;
    justify-content: flex-start;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
}

.ic-image-title-mobile {
    color: var(--text-primary);
    font-weight: bold;
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;
    justify-content: flex-start;
    border-radius: 0.5rem;
}

.ic-more-options .ic-action-button:hover {
    background-color: var(--background-primary);
}

.ic-more-options .ic-action-button p {
    margin-left: 0.5rem;
    font-size: 1rem;
}

.ic-quick-actions {
    position: absolute;
    display: flex;
    gap: 3rem;
    z-index: 50;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    left: -1rem;
}

.ic-quick-actions .ic-action-button {
    gap: 0.2rem;
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    transform: rotate(90deg);
}

.ic-quick-actions .ic-action-button span {
    font-size: 0.65rem;
}

.ic-user-info {
    display: flex;
    gap: 0.3rem;
    margin-left: 0.2rem;
}

.ic-user-info .ic-user-link.ic-username {
    color: var(--text-primary);
    margin-bottom: 6px;
    min-width: fit-content;
}

.ic-user-info .ic-user-avatar-container {
    margin: 0;
    width: fit-content;
    height: fit-content;
}

.ic-action-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease;
    opacity: 0.5;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    pointer-events: none;
}

.ic-more-options .ic-action-button::after {
    display: none;
}


.ic-settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
}

.ic-settings-content {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
}

.ic-settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ic-settings-header h3 {
    margin: 0;
    color: var(--text-primary);
}

.ic-settings-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ic-settings-item {
    display: flex;
    gap: 8px;
}

.ic-settings-prompt {
    max-height: 150px;
    overflow-y: auto;
}

.ic-settings-label {
    font-weight: 600;
    min-width: 80px;
}

.ic-settings-prompt {
    word-break: break-word;
}

.ic-settings-content .ic-action-button {
    background-color: var(--background-secondary);
    color: var(--text-primary);
}

.ic-settings-content .ic-action-button.ic-copy-prompt {
    width: fit-content;
    display: flex;
    justify-content: end;
    padding: 0.5rem;
    border-radius: 3px;
    margin-left: auto;
    margin-top: 0.5rem;
}

.ic-settings-content .ic-action-button svg {
    stroke: var(--text-primary);
}

/* Animations */
@keyframes heartBeat {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }

    15% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
    }

    30% {
        transform: translate(-50%, -50%) scale(0.95);
    }

    45%,
    80% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ic-more-options .ic-action-button {
    color: var(--text-primary);
}

.ic-more-options .ic-action-button svg {
    stroke: var(--text-primary);
}

.ic-liked-users-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 400px;
    overflow-y: auto;
    padding: 8px;
}

.ic-liked-user-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.ic-liked-user-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.ic-liked-user-item .ic-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.ic-liked-user-item .ic-user-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

.ic-liked-user-item .ic-username {
    font-weight: 500;
}

.ic-no-likes {
    text-align: center;
    padding: 24px;
    color: #666;
}

.ic-avatar-placeholder {
    width: 33px;
    height: 33px;
    margin-top: -5px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    color: #666;
    border-radius: 50%;
    border: 1px solid var(--text-primary);
}

.ic-image-date {
    font-size: 0.7rem;
    margin: auto;
}

.ic-username-date {
    margin-top: -5px;
}

.ic-action-button.ic-comment-button .ic-comment-count {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
}

.ic-close-more-options-button {
    position: absolute;
    right: -5px;
    top: -2px;
    padding: 0.3rem 1.2rem;
    border-bottom-left-radius: 0.3rem;
    font-weight: bold;
    cursor: pointer;
    text-align: end;
    width: fit-content;
    margin-left: auto;
    z-index: 99;

    &:hover {
        transform: scale(0.95);
        color: var(--button-text);
        background: var(--button-background);
    }
}

.ldv-original-badge,
.ic-type-badge {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    z-index: 1;
    background: #808080;
    color: #fff;
    display: flex;
    gap: 2px;
}

.ldv-original-badge {
    background: var(--button-background);
    color: var(--button-text);
    border: var(--border-color);
}

.ic-status-badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    z-index: 1;
}

.ic-status-badge svg {
    width: 20px;
    height: 20px;
}


.ic-status-badge[data-status="approved"] {
    color: var(--button-text);
}

.ic-status-badge[data-status="approved"] svg {
    fill: #10B981;
    stroke: #fff;
}

.ic-status-badge[data-status="pending"] svg {
    fill: #F59E0B;
    stroke: #fff;
}

.ic-status-badge[data-status="pending"] {
    color: var(--button-text);
}

.ic-badge-container {
    position: relative;
}

.ic-badge-tooltip {
    position: absolute;
    top: 60%;
    right: 80%;
    background: #222;
    color: #fff;
    padding: 0.1rem 0.2rem;
    border-radius: 0.3rem;
    font-size: 0.6rem;
    display: none;
}

.ic-status-badge:hover .ic-badge-tooltip {
    display: block;
}

@media (max-width: 786px) {
    .ic-status-badge {
        top: 0.1rem;
        right: 0.1rem;
        padding: 0.15rem;
        border-radius: 15px;
        font-size: 0.6rem;
        font-weight: 300;
    }

    .ic-status-badge svg {
        width: 14px;
        height: 14px;
    }

    .ldv-original-badge,
    .ic-type-badge {
        position: absolute;
        top: 0.3rem;
        left: 0.3rem;
        padding: 0.15rem;
        border-radius: 15px;
        font-size: 0.6rem;
        font-weight: 300;
        z-index: 1;
    }

    .ldv-original-badge {
        font-size: 0.5rem;
    }
}