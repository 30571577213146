.filter-container {
    background: var(--background-primary);
    border-radius: 12px;
    box-shadow: var(--card-shadow);
    padding: 24px;
    width: 90vw;
    max-width: 1200px;
    position: fixed;
    z-index: 2200;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%);
    opacity: 0;
    max-height: 90vh;
    overflow-y: auto;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
}

.filter-container.visible {
    opacity: 1;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    cursor: default;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    position: sticky;
    /* Make header sticky */
    top: 0;
    background: var(--background-primary);
    padding: 8px 0;
    z-index: 2;
}

.filter-header h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
}

.clear-filters {
    background: var(--background-secondary);
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    font-size: 0.95rem;
    padding: 8px 16px;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.clear-filters:hover {
    background: var(--text-secondary);
    color: var(--button-text);
}

/* Filter categories */
.filter-category {
    margin-bottom: 20px;
}

.category-header h4 {
    margin: 0 0 12px 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-primary);
    text-transform: capitalize;
}

.filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.filter-option {
    background: var(--background-secondary);
    color: var(--text-primary);
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}

.filter-option:hover {
    background: var(--background-secondary-hover);
    transform: translateY(-1px);
}

.filter-option.active {
    background: var(--button-background);
    color: var(--button-text);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .filter-container {
        width: 100vw;
        height: 80vh;
        padding: 16px;
        border-radius: 12px 12px 0 0;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
    }

    .filter-container.visible {
        transform: translateY(0);
        padding-top: 0;
    }

    .filter-header {
        padding: 16px;
        margin: -16px -16px 16px -16px;
        /* Negative margin to extend to edges */
        border-bottom: 1px solid var(--border-color);
    }

    .filter-header h3 {
        font-size: 1.25rem;
    }

    .filter-option {
        padding: 6px 12px;
        font-size: 0.9rem;
    }

    /* Ensure content doesn't get hidden behind the header */
    .filter-category:first-of-type {
        margin-top: 8px;
    }
}

/* Scrollbar styling */
.filter-container::-webkit-scrollbar {
    width: 6px;
}

.filter-container::-webkit-scrollbar-track {
    background: var(--background-primary-800);
    border-radius: 3px;
}

.filter-container::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 3px;
}