/* RandomLoaderMetronome.css */
.lp-metronome-container {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    transform: scale(0.3) translateX(-50%);
}

.imgen-images-grid.multi-column .lp-metronome-container {
    height: 50px;
    width: 50px;
    transform: scale(0.2) translateX(-270%);
}

.lp-baton {
    display: block;
    height: 2px;
    background-color: var(--background-secondary);
    animation: lp-scale 1.25s infinite linear;
    transform-origin: 0;
    transform: rotate(-10deg);
    position: relative;
}

.lp-baton::before,
.lp-baton::after {
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    background-color: var(--button-background);
    position: absolute;
    top: -2px;
    border-radius: 5px;
}

.lp-baton::after {
    right: 0;
}

.lp-metronome {
    transform-origin: 0;
    animation: lp-metronome 1.25s infinite linear;
}

/* Set up the baton positions and common properties */
[class^="lp-baton-"] {
    height: 1px;
    width: 150px;
    position: absolute;
    top: calc(50% - 8px);
    transform-origin: 100%;
}

@media (max-width: 786px) {
    .lp-metronome-container {
        margin-top: 0.5rem;
    }

    .lp-baton {
        width: 60px;
    }

    [class^="lp-baton-"] {
        transform-origin: 75%;
    }
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .lp-metronome-container {
        height: 100px;
        width: 100px;
        transform: scale(0.3) translateX(-70%);
    }

    .lp-loader-container {
        min-height: 100px;
    }

    .imgen-images-grid.multi-column .lp-metronome-container {
        height: 120px;
        width: 120px;
        transform: scale(0.2) translateX(-60%);
    }
}


/* Generate the 36 batons with their positions */
.lp-baton-0 {
    transform: rotate(0deg);
}

.lp-baton-1 {
    transform: rotate(10deg);
}

.lp-baton-2 {
    transform: rotate(20deg);
}

.lp-baton-3 {
    transform: rotate(30deg);
}

.lp-baton-4 {
    transform: rotate(40deg);
}

.lp-baton-5 {
    transform: rotate(50deg);
}

.lp-baton-6 {
    transform: rotate(60deg);
}

.lp-baton-7 {
    transform: rotate(70deg);
}

.lp-baton-8 {
    transform: rotate(80deg);
}

.lp-baton-9 {
    transform: rotate(90deg);
}

.lp-baton-10 {
    transform: rotate(100deg);
}

.lp-baton-11 {
    transform: rotate(110deg);
}

.lp-baton-12 {
    transform: rotate(120deg);
}

.lp-baton-13 {
    transform: rotate(130deg);
}

.lp-baton-14 {
    transform: rotate(140deg);
}

.lp-baton-15 {
    transform: rotate(150deg);
}

.lp-baton-16 {
    transform: rotate(160deg);
}

.lp-baton-17 {
    transform: rotate(170deg);
}

.lp-baton-18 {
    transform: rotate(180deg);
}

.lp-baton-19 {
    transform: rotate(190deg);
}

.lp-baton-20 {
    transform: rotate(200deg);
}

.lp-baton-21 {
    transform: rotate(210deg);
}

.lp-baton-22 {
    transform: rotate(220deg);
}

.lp-baton-23 {
    transform: rotate(230deg);
}

.lp-baton-24 {
    transform: rotate(240deg);
}

.lp-baton-25 {
    transform: rotate(250deg);
}

.lp-baton-26 {
    transform: rotate(260deg);
}

.lp-baton-27 {
    transform: rotate(270deg);
}

.lp-baton-28 {
    transform: rotate(280deg);
}

.lp-baton-29 {
    transform: rotate(290deg);
}

.lp-baton-30 {
    transform: rotate(300deg);
}

.lp-baton-31 {
    transform: rotate(310deg);
}

.lp-baton-32 {
    transform: rotate(320deg);
}

.lp-baton-33 {
    transform: rotate(330deg);
}

.lp-baton-34 {
    transform: rotate(340deg);
}

.lp-baton-35 {
    transform: rotate(350deg);
}

/* Animation delays for each baton */
.lp-baton-0 .lp-baton,
.lp-baton-0 .lp-baton::after,
.lp-baton-0 .lp-metronome {
    animation-delay: 0s;
}

.lp-baton-1 .lp-baton,
.lp-baton-1 .lp-baton::after,
.lp-baton-1 .lp-metronome {
    animation-delay: -0.14s;
}

.lp-baton-2 .lp-baton,
.lp-baton-2 .lp-baton::after,
.lp-baton-2 .lp-metronome {
    animation-delay: -0.28s;
}

.lp-baton-3 .lp-baton,
.lp-baton-3 .lp-baton::after,
.lp-baton-3 .lp-metronome {
    animation-delay: -0.42s;
}

.lp-baton-4 .lp-baton,
.lp-baton-4 .lp-baton::after,
.lp-baton-4 .lp-metronome {
    animation-delay: -0.56s;
}

.lp-baton-5 .lp-baton,
.lp-baton-5 .lp-baton::after,
.lp-baton-5 .lp-metronome {
    animation-delay: -0.70s;
}

.lp-baton-6 .lp-baton,
.lp-baton-6 .lp-baton::after,
.lp-baton-6 .lp-metronome {
    animation-delay: -0.84s;
}

.lp-baton-7 .lp-baton,
.lp-baton-7 .lp-baton::after,
.lp-baton-7 .lp-metronome {
    animation-delay: -0.98s;
}

.lp-baton-8 .lp-baton,
.lp-baton-8 .lp-baton::after,
.lp-baton-8 .lp-metronome {
    animation-delay: -1.12s;
}

.lp-baton-9 .lp-baton,
.lp-baton-9 .lp-baton::after,
.lp-baton-9 .lp-metronome {
    animation-delay: -1.26s;
}

.lp-baton-10 .lp-baton,
.lp-baton-10 .lp-baton::after,
.lp-baton-10 .lp-metronome {
    animation-delay: -1.40s;
}

.lp-baton-11 .lp-baton,
.lp-baton-11 .lp-baton::after,
.lp-baton-11 .lp-metronome {
    animation-delay: -1.54s;
}

.lp-baton-12 .lp-baton,
.lp-baton-12 .lp-baton::after,
.lp-baton-12 .lp-metronome {
    animation-delay: -1.68s;
}

.lp-baton-13 .lp-baton,
.lp-baton-13 .lp-baton::after,
.lp-baton-13 .lp-metronome {
    animation-delay: -1.82s;
}

.lp-baton-14 .lp-baton,
.lp-baton-14 .lp-baton::after,
.lp-baton-14 .lp-metronome {
    animation-delay: -1.96s;
}

.lp-baton-15 .lp-baton,
.lp-baton-15 .lp-baton::after,
.lp-baton-15 .lp-metronome {
    animation-delay: -2.10s;
}

.lp-baton-16 .lp-baton,
.lp-baton-16 .lp-baton::after,
.lp-baton-16 .lp-metronome {
    animation-delay: -2.24s;
}

.lp-baton-17 .lp-baton,
.lp-baton-17 .lp-baton::after,
.lp-baton-17 .lp-metronome {
    animation-delay: -2.38s;
}

.lp-baton-18 .lp-baton,
.lp-baton-18 .lp-baton::after,
.lp-baton-18 .lp-metronome {
    animation-delay: -2.52s;
}

.lp-baton-19 .lp-baton,
.lp-baton-19 .lp-baton::after,
.lp-baton-19 .lp-metronome {
    animation-delay: -2.66s;
}

.lp-baton-20 .lp-baton,
.lp-baton-20 .lp-baton::after,
.lp-baton-20 .lp-metronome {
    animation-delay: -2.80s;
}

.lp-baton-21 .lp-baton,
.lp-baton-21 .lp-baton::after,
.lp-baton-21 .lp-metronome {
    animation-delay: -2.94s;
}

.lp-baton-22 .lp-baton,
.lp-baton-22 .lp-baton::after,
.lp-baton-22 .lp-metronome {
    animation-delay: -3.08s;
}

.lp-baton-23 .lp-baton,
.lp-baton-23 .lp-baton::after,
.lp-baton-23 .lp-metronome {
    animation-delay: -3.22s;
}

.lp-baton-24 .lp-baton,
.lp-baton-24 .lp-baton::after,
.lp-baton-24 .lp-metronome {
    animation-delay: -3.36s;
}

.lp-baton-25 .lp-baton,
.lp-baton-25 .lp-baton::after,
.lp-baton-25 .lp-metronome {
    animation-delay: -3.50s;
}

.lp-baton-26 .lp-baton,
.lp-baton-26 .lp-baton::after,
.lp-baton-26 .lp-metronome {
    animation-delay: -3.64s;
}

.lp-baton-27 .lp-baton,
.lp-baton-27 .lp-baton::after,
.lp-baton-27 .lp-metronome {
    animation-delay: -3.78s;
}

.lp-baton-28 .lp-baton,
.lp-baton-28 .lp-baton::after,
.lp-baton-28 .lp-metronome {
    animation-delay: -3.92s;
}

.lp-baton-29 .lp-baton,
.lp-baton-29 .lp-baton::after,
.lp-baton-29 .lp-metronome {
    animation-delay: -4.06s;
}

.lp-baton-30 .lp-baton,
.lp-baton-30 .lp-baton::after,
.lp-baton-30 .lp-metronome {
    animation-delay: -4.20s;
}

.lp-baton-31 .lp-baton,
.lp-baton-31 .lp-baton::after,
.lp-baton-31 .lp-metronome {
    animation-delay: -4.34s;
}

.lp-baton-32 .lp-baton,
.lp-baton-32 .lp-baton::after,
.lp-baton-32 .lp-metronome {
    animation-delay: -4.48s;
}

.lp-baton-33 .lp-baton,
.lp-baton-33 .lp-baton::after,
.lp-baton-33 .lp-metronome {
    animation-delay: -4.62s;
}

.lp-baton-34 .lp-baton,
.lp-baton-34 .lp-baton::after,
.lp-baton-34 .lp-metronome {
    animation-delay: -4.76s;
}

.lp-baton-35 .lp-baton,
.lp-baton-35 .lp-baton::after,
.lp-baton-35 .lp-metronome {
    animation-delay: -4.90s;
}

@keyframes lp-metronome {
    0% {
        transform: rotate(-25deg);
    }

    50% {
        transform: rotate(25deg);
    }

    100% {
        transform: rotate(-25deg);
    }
}

@keyframes lp-scale {
    0% {
        transform: scaleX(1);
    }

    25% {
        transform: scaleX(0.74);
    }

    50% {
        transform: scaleX(1);
    }

    75% {
        transform: scaleX(1.16);
    }

    100% {
        transform: scaleX(1);
    }
}