.unsubscribe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-primary);
    font-family: Arial, sans-serif;
}

.unsubscribe-box {
    background-color: var(--background-secondary);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.unsubscribe-box h2 {
    color: var(--text-primary);
    margin-bottom: 20px;
}

.unsubscribe-box p {
    color: var(--text-secondary);
    margin-bottom: 25px;
}

.unsubscribe-box input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 16px;
}

.unsubscribe-box button {
    width: 100%;
    padding: 12px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.unsubscribe-box button:hover {
    background-color: var(--background-primary);
    color: var(--button-background);
}

.success-message {
    color: green;
    margin-top: 15px;
}

.error-message {
    color: red;
    margin-top: 15px;
}