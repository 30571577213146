@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary-brand-color-bright: #a01aff;
  --primary-brand-color-dark: #6A0DAD;
  --secondary-brand-color: #808080;

  /* Primary Colors - Black & White */
  --primary-100: #ffffff;
  --primary-200: #f8f8f8;
  --primary-300: #ebebeb;
  --primary-400: #d3d3d3;
  --primary-500: #b3b3b3;
  --primary-600: #757575;
  --primary-700: #4d4d4d;
  --primary-800: #2a2a2a;
  --primary-900: #1a1a1a;

  /* Neutral Colors */
  --neutral-100: #ffffff;
  --neutral-200: #f2f2f2;
  --neutral-300: #e6e6e6;
  --neutral-400: #cfcfcf;
  --neutral-500: #999999;
  --neutral-600: #737373;
  --neutral-700: #4a4a4a;
  --neutral-800: #333333;
  --neutral-900: #1a1a1a;

  /* Status Colors */
  --success: #28a745;
  --warning: #ffc107;
  --error: #dc3545;
  --info: #007bff;

  /* Theme Tokens */
  --background-primary: var(--neutral-100);
  --background-secondary: var(--neutral-200);
  --text-primary: var(--neutral-900);
  --text-secondary: var(--neutral-700);
  --border-color: var(--neutral-400);

  /* Component-specific */
  --button-background: var(--primary-800);
  --button-text: var(--primary-100);
  --input-background: var(--neutral-100);
  --input-border: var(--neutral-500);
  --card-background: var(--neutral-100);
  --card-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  --header-active-button: var(--neutral-300);
}

[data-theme="dark"] {
  --background-primary: var(--neutral-900);
  --background-primary-800: #1a1a1aa5;
  --background-secondary: var(--neutral-800);
  --text-primary: var(--neutral-100);
  --text-secondary: var(--neutral-400);
  --border-color: var(--neutral-700);

  --button-background: var(--neutral-100);
  --button-text: var(--neutral-900);
  --input-background: var(--neutral-700);
  --input-border: var(--neutral-500);
  --card-background: var(--neutral-800);
  --card-shadow: 0 2px 6px rgba(255, 255, 255, 0.1);
  --header-active-button: var(--neutral-600);
}

[data-theme="crimson-night"] {
  --background-primary: #1E1E2F;
  --background-secondary: #252536;
  --background-primary-800: #2C2C3D;
  --card-background: #2F2F40;
  --input-background: #3A3A4F;
  --input-border: #505061;
  --border-color: #414150;
  --text-primary: #FFFFFF;
  --text-secondary: #B0B0C3;
  --button-background: #FF6F61;
  --button-text: #FFFFFF;
  --button-hover: #E65B50;
  --header-active-button: #FF5A4C;
  --card-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

[data-theme="nature"] {
  --background-primary: #f4f8f2;
  --background-primary-800: #f4f8f2a6;
  --background-secondary: #e8f0e3;
  --text-primary: #2c4a1d;
  --text-secondary: #4a6d39;
  --border-color: #a4c596;

  --button-background: #5c8c4d;
  --button-text: #ffffff;
  --input-background: #ffffff;
  --input-border: #88b475;
  --card-background: #ffffff;
  --card-shadow: 0 2px 6px rgba(92, 140, 77, 0.2);
  --header-active-button: #88b475;
}

[data-theme="ocean"] {
  --background-primary: #f0f7f9;
  --background-primary-800: #f0f7f9a2;
  --background-secondary: #e1eff3;
  --text-primary: #1a4b5c;
  --text-secondary: #326579;
  --border-color: #89c3d6;

  --button-background: #2b7da1;
  --button-text: #ffffff;
  --input-background: #ffffff;
  --input-border: #7ab8d0;
  --card-background: #ffffff;
  --card-shadow: 0 2px 6px rgba(43, 125, 161, 0.2);
  --header-active-button: #7ab8d0;
}

[data-theme="sunset"] {
  --background-primary: #fff5f0;
  --background-primary-800: #fff5f0a2;
  --background-secondary: #ffe6db;
  --text-primary: #8c3d2b;
  --text-secondary: #b15642;
  --border-color: #e6947f;

  --button-background: #d95d44;
  --button-text: #ffffff;
  --input-background: #ffffff;
  --input-border: #e6947f;
  --card-background: #ffffff;
  --card-shadow: 0 2px 6px rgba(217, 93, 68, 0.2);
  --header-active-button: #e6947f;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background: var(--background-secondary);
  color: var(--text-primary);
}

main {
  width: 100%;
  margin: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-margin {
  margin: 80px auto;
}

.heading-search {
  width: 85%;
  margin: auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

.heading-search h1 {
  font-size: 2rem;
  color: var(--text-primary);
}

.heading-search input {
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  background-color: var(--input-background);
  border: 1px solid var(--input-border);
  color: var(--text-primary);
}

.generate-image-button {
  margin: 20px auto;
  background-color: var(--button-background);
  color: var(--button-text);
}

.margin {
  margin: 80px auto !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
  margin-bottom: -20px;
  padding: 1px 0;
}

/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {
  body {
    font-size: 14;
  }
}

/* Mobile */
@media (max-width: 767px) {

  html,
  body {
    overflow-x: hidden;
  }

  body {
    font-size: 10px;
  }

  main {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }

  body {
    font-size: 15px;
  }
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {}