.gcc-community-chat {
    position: relative;
    background-color: var(--card-background);
    box-shadow: var(--card-shadow);
    border-radius: 16px;
    max-width: 1200px;
    height: 80vh;
    margin: 30px auto;
    overflow: hidden;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.gcc-messages-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    background-color: var(--background-primary);
    scrollbar-width: thin;
    scrollbar-color: var(--input-background) transparent;
    transition: background-color 0.3s ease;
}

.gcc-message::-webkit-scrollbar,
.gcc-messages-container::-webkit-scrollbar {
    width: 8px;
}

.gcc-message::-webkit-scrollbar-track,
.gcc-messages-container::-webkit-scrollbar-track {
    background: transparent;
}

.gcc-message::-webkit-scrollbar-thumb,
.gcc-messages-container::-webkit-scrollbar-thumb {
    background-color: var(--input-background);
    border-radius: 8px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.gcc-message {
    margin-bottom: 14px;
    background-color: var(--background-primary-800);
    padding: 14px;
    border-radius: 12px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.gcc-heading {
    margin: auto;
    width: fit-content;
    padding: 0 2rem;
    font-size: 2rem;
    margin-top: 2rem;
}


.gcc-message li {
    margin: 0.2rem 0;
    margin-left: 1rem;
}

.gcc-message:hover {
    background-color: var(--background-secondary);
    transform: translateX(2px);
}

.gcc-message-username {
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--text-primary);
}

.gcc-message-username::after {
    content: ":";
    margin-left: 4px;
}

.gcc-message-form {
    display: flex;
    align-items: center;
    padding: 14px;
    background-color: var(--card-background);
    border-top: 1px solid var(--border-color);
    gap: 10px;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
}

.gcc-message-input {
    flex-grow: 1;
    padding: 12px 14px;
    background-color: var(--input-background);
    color: var(--text-primary);
    border: 1px solid var(--input-border);
    border-radius: 12px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-size: 16px;
    font-family: inherit;
    font-weight: inherit;
    resize: vertical;
}

.gcc-message-input:focus {
    outline: none;
    border-color: var(--button-background);
    background-color: var(--background-secondary);
    box-shadow: 0 0 5px var(--button-background);
}

.gcc-message-input.gcc-bot-command {
    border-color: #4a90e2;
    box-shadow: 0 0 7px rgba(74, 144, 226, 0.7);
}

.gcc-bot-command-label {
    position: absolute;
    right: 50px;
    top: -12px;
    background: inherit;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.8em;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.gcc-send-button {
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 12px 18px;
    border-radius: 12px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.gcc-send-button:hover:not(:disabled) {
    background-color: var(--button-hover);
    transform: scale(1.05);
}

.gcc-send-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
.gcc-container,
.gcc-loading {
    text-align: center;
    font-size: 18px;
    color: var(--text-secondary);
    padding: 30px;
    background-color: var(--background-primary);
}

.gcc-input-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.gcc-input-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.gcc-info-icon {
    position: relative;
    cursor: pointer;
    color: var(--text-secondary);
    transition: color 0.3s ease;
}

.gcc-info-icon:hover {
    color: var(--button-background);
}

.gcc-tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-60%);
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 12px;
    width: 250px;
    z-index: 550;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, visibility 0.3s;
}

.gcc-info-icon:hover .gcc-tooltip {
    visibility: visible;
    opacity: 1;
}

.gcc-chat-instructions {
    font-size: 14px;
}

.gcc-chat-instructions ul {
    padding-left: 20px;
    margin: 8px 0 0;
}

.gcc-chat-instructions li {
    margin-bottom: 6px;
}

.gcc-chat-instructions code {
    background-color: var(--input-background);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
    font-size: 12px;
}

.gcc-join-notification {
    position: absolute;
    top: 0;
    text-align: center;
    color: var(--text-secondary);
    font-size: 0.8rem;
    padding: 10px 0;
    background: var(--background-primary);
    width: 100%;
    text-align: center;
    z-index: 600;
}

.gcc-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.gcc-message-timestamp {
    color: var(--text-secondary);
    font-size: 0.75rem;
    margin-left: 8px;
}

/* Image Loader Styles */
.gcc-image-container {
    position: relative;
    display: inline-block;
    z-index: 500;
}

.gcc-image-loader {
    position: relative;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gcc-spinner {
    width: 11px;
    height: 11px;
    border: 3px solid var(--button-text);
    border-top: 3px solid var(--button-background);
    border-radius: 50%;
    margin: auto;
}

.gcc-image-error {
    color: orange;
    font-style: italic;
}

.gcc-loaded-image {
    max-width: 100%;
    max-height: 200px;
    height: auto;
    border-radius: 8px;
    margin-top: 4px;
    margin-right: 4px;
}

@media (max-width: 768px) {
    .gcc-heading{
        margin-top: 4rem;
        font-size: 1.3rem;
    }

    .gcc-community-chat {
        min-width: 100vw;
        border-radius: 12px;
        height: 75vh;
        margin: 10px 0;
        border-radius: 0;
    }

    .gcc-message-form {
        flex-direction: column;
        gap: 12px;
    }

    .gcc-input-container {
        width: 100%;
    }

    .gcc-message-input {
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
    }

    .gcc-send-button {
        width: 100%;
        padding: 10px 0;
    }

    .gcc-tooltip {
        width: calc(100vw - 60px);
        transform: translateX(-50%);
        left: auto;
        right: 0;
        transform: none;
    }

    .gcc-join-notification {
        font-size: 0.7rem;
    }
}