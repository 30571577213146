/* ChatIcon.css */
.ci-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 1000;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--button-background);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--card-shadow);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    animation: ci-bounce 2s ease-in-out infinite;
}

.ci-button:hover {
    transform: scale(1.1);
}

.ci-button--active {
    animation: none;
}

.ci-button--active:hover {
    background-color: var(--button-background);
}

.ci-icon {
    width: 24px;
    height: 24px;
    color: var(--button-text);
}

.ci-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.ci-overlay--visible {
    opacity: 1;
    visibility: visible;
}

.ci-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1200px;
    max-width: 90vw;
    height: 80vh;
    z-index: 1000;
    transform: translate(-50%, -45%) scale(0.95);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.ci-popup--visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
}

.ci-popup__container {
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}

@keyframes ci-bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-6px);
    }
}

/* Modify CommunityChat styles when in popup */
.ci-popup .gcc-community-chat {
    position: relative;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 16px !important;
    background-color: var(--card-background);
}

.ci-popup .gcc-heading {
    margin-top: 1.5rem !important;
}

/* Media Queries */
@media (max-width: 768px) {
    .ci-button {
        bottom: 16px;
        right: 16px;
        width: 48px;
        height: 48px;
    }

    .ci-popup {
        width: 100%;
        height: 80%;
        max-width: 100%;
        border-radius: 0;
    }

    .ci-popup__container {
        border-radius: 0;
    }

    .ci-popup .gcc-community-chat {
        border-radius: 0 !important;
    }
}
