.cs-container {
    width: 100%;
    background-color: var(--background-secondary);
    padding: 4rem 2rem;
}

.cs-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.cs-header {
    text-align: center;
}

.cs-category-filter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.cs-category-btn {
    padding: 0.5rem 1rem;
    border: 2px solid var(--border-color);
    background-color: var(--background-primary);
    color: var(--text-secondary);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cs-category-btn.active {
    background-color: var(--text-primary);
    color: var(--background-primary);
    border-color: var(--text-primary);
}

.cs-designs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.cs-design-card {
    background-color: var(--background-primary);
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: var(--card-shadow);
    transition: all 0.3s ease;
    cursor: pointer;
}

.cs-design-card:hover{
    box-shadow: none;
    transform: scale(0.99);
}

.cs-designs-grid a{
    text-decoration: none;
    color: var(--text-primary);
}

.cs-design-image-container {
    width: 100%;
    overflow: hidden;
}

.cs-design-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cs-design-info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cs-design-title{
    font-size: 1rem;
}

.cs-design-meta {
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.cs-cta-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    background-color: var(--background-secondary);
    padding: 3rem 2rem;
    border-radius: 0.75rem;
    margin-top: 1rem;
}

.cs-cta-description{
    width: 90%;
    margin: auto;
}

.cs-design-creator a:hover{
    text-decoration: underline;
}

.cs-cta-section a{
    text-decoration: none;
    width: fit-content;
    margin: auto;
}

.cs-cta-button {
    margin-top: 1.5rem;
    padding: 1rem 2rem;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cs-cta-button:hover {
    background-color: var(--primary-700);
}

@media screen and (max-width: 768px) {
    .cs-cta-section{
        padding: 0.5rem;
    }

    .cs-design-info{
         padding: 0.2rem;
    }
    .cs-designs-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .cs-design-title {
        font-size: 0.8rem;
    }

    .cs-design-meta {
        font-size: 0.6rem;
    }

    .cs-category-filter {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 480px) {
    .cs-container {
        padding: 2rem 1rem;
    }

    .cs-category-filter {
        gap: 0.5rem;
    }

    .cs-category-btn {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }

}