/* Base overlay styles */
.ldv-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 2005;
    opacity: 0;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    pointer-events: none;
}

.ldv-overlay-full-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.8);
}

.design-viewer-page-loader {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100dvh;
}

.ldv-loading-overlay svg {
    animation: spin 1s linear infinite;
}

.ldv-overlay.visible {
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 1;
    pointer-events: auto;
}

/* Main container */
.ldv-container {
    background-color: var(--card-background);
    color: var(--text-primary);
    border-radius: 0.75rem;
    width: 100%;
    max-width: 1400px;
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transform: scale(0.95) translateY(20px);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.ldv-overlay.visible .ldv-container {
    transform: scale(1) translateY(0);
    opacity: 1;
}

/* Scrollbar styling */
.ldv-images-scroll::-webkit-scrollbar {
    width: 8px;
}

.ldv-images-scroll::-webkit-scrollbar-track {
    background: var(--background-primary-800);
    border-radius: 3px;
}

.ldv-images-scroll::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 3px;
}

/* Main content area */
.ldv-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 1.5rem;
}

.ldv-title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0 2rem;
}

/* Layout */
.ldv-layout {
    display: flex;
    flex: 1;
    gap: 1.5rem;
    min-height: 0;
}

/* Related images section */
.ldv-related-images {
    width: 300px;
    flex-shrink: 0;
    border-right: 1px solid var(--input-border);
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
}

.ldv-related-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

/* Images scroll container */
.ldv-images-scroll {
    flex: 1;
    overflow-y: auto;
    position: relative;
    padding: 0.5rem;
}

.ldv-no-related a {
    text-decoration: none;
    color: var(--text-primary);
    margin-right: 5px;

    &:hover {
        text-decoration: underline;
    }
}

.ldv-images-row {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.ldv-related-item {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease;
    width: 100%;
}

.ldv-related-item .ic-image.ic-loaded {
    border: 1px solid var(--input-border);
    width: 100%;
    height: auto;
    aspect-ratio: auto;
}

.ldv-related-item .image-card {
    margin: 0;
    width: 100%;
}

.ldv-related-item .image-card-image-container {
    width: 100%;
    height: auto;
}

@media (max-width: 900px) {

    /* Related image items */
    .ldv-related-item {
        height: 100% !important;
        width: fit-content !important;
    }

    /* Images scroll container */
    .ldv-images-scroll {
        height: 100% !important;
    }

    .ldv-images-row {
        height: 100% !important;
    }

    .ldv-related-item .ic-image-card,
    .ldv-related-item .ic-image-wrapper,
    .ldv-related-item .ic-image.ic-loaded,
    .ldv-related-item .lazyload-wrapper {
        height: 100% !important;
        width: fit-content;
    }
}


.ldv-related-item .image-card {
    margin: 0;
    width: 100%;
}

.ldv-related-item .image-card-image-container {
    aspect-ratio: 1;
}

.ldv-related-item .image-card-actions,
.ldv-related-item .image-card-footer {
    display: none;
}

/* Main content area */
.ldv-main-content {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Image wrapper */
.ldv-image-wrapper {
    position: relative;
    flex: 1;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-secondary);
    border-radius: 0.75rem;
    overflow: hidden;
}

.ldv-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease;
}

/* Loading states */
.ldv-loading-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 1;
    color: white;
    gap: 1rem;
}

.ldv-loading-related {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--text-secondary);
}

.ldv-no-related {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
}

/* Input area */
.ldv-input-wrapper {
    display: flex;
    gap: 0.75rem;
    padding: 1rem;
    background-color: var(--card-background);
    border-top: 1px solid var(--input-border);
    position: relative;
}

.ldv-input {
    flex: 1;
    min-width: 0;
    padding: 0.75rem 1rem;
    border: 2px solid var(--input-border);
    border-radius: 0.5rem;
    font-size: 1rem;
    background-color: var(--card-background);
    color: var(--text-primary);
    transition: all 0.2s ease;
}

.ldv-input:focus {
    outline: none;
    border-color: var(--button-background);
    box-shadow: 0 0 0 2px rgba(var(--button-background-rgb), 0.1);
}

.ldv-input:disabled {
    background-color: var(--input-border);
    opacity: 0.7;
    cursor: not-allowed;
}

/* Buttons */
.ldv-submit-button {
    padding: 0.75rem 1.5rem;
    background-color: var(--button-background);
    color: var(--button-text);
    border-radius: 0.5rem;
    font-weight: 500;
    white-space: nowrap;
    min-width: 120px;
    transition: all 0.2s ease;
}

.ldv-submit-button:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ldv-submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.ldv-image-actions {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    gap: 0.5rem;
    z-index: 5;
}

.ldv-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--card-background);
    color: var(--text-primary);
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.ldv-action-button:not(:disabled):hover {
    transform: translateY(-1px);
    background-color: var(--button-background);
    color: var(--button-text);
}

.ldv-action-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}


/* Mobile styles */
@media (max-width: 900px) {
    .ldv-layout {
        flex-direction: column;
        height: calc(100% - 120px);
        /* Account for header and input area */
        overflow: hidden;
    }

    .ldv-related-images {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--input-border);
        padding-right: 0;
        padding-bottom: 1rem;
        max-height: 180px;
        /* Reduced height for related images */
        min-height: auto;
    }


    .ldv-images-scroll {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .ldv-images-row {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
        padding-bottom: 0.75rem;
    }

    .ldv-main-content {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .ldv-related-item {
        flex: 0 0 180px;
        width: 180px;
    }

    .ldv-image-wrapper {
        flex: 1;
        min-height: 0;
        max-height: calc(100vh - 400px);
    }

    .ldv-image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
    }

    .ldv-images-scroll::-webkit-scrollbar {
        height: 4px;
    }

    .ldv-images-scroll::-webkit-scrollbar-track {
        background: transparent;
    }

    .ldv-images-scroll::-webkit-scrollbar-thumb {
        background-color: var(--input-border);
        border-radius: 2px;
    }
}

@media (max-width: 640px) {

    .ldv-action-button {
        padding: 0.4rem 0.8rem;
    }

    .ldv-action-text {
        display: none;
    }

    .ldv-overlay {
        padding: 0;
    }

    .ldv-container {
        height: 100vh;
        border-radius: 0;
        max-height: 100vh;
        overflow: hidden;
    }



    .ldv-overlay.visible .ldv-container {
        transform: translateY(0);
        height: 100dvh;
    }

    .ldv-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .ldv-title {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }

    .ldv-layout {
        gap: 1rem;
    }

    /* Adjust sizes for mobile */
    .ldv-related-images {
        max-height: 160px;
    }

    .ldv-main-content {
        flex: 1;
        min-height: 0;
    }

    .ldv-image-wrapper {
        max-height: none;
        /* Remove fixed max-height */
    }

    /* Input area adjustments */
    .ldv-input-wrapper {
        flex-shrink: 0;
        padding: 0.75rem;
        background-color: var(--card-background);
        border-top: 1px solid var(--input-border);
    }

    .ldv-masonry-grid {
        height: 150px;
    }

    .ldv-related-item {
        width: 150px;
    }

    .ldv-input-wrapper {
        flex-direction: column;
        padding: 0.75rem;
    }

    .ldv-input {
        width: 100%;
    }

    .ldv-submit-button {
        width: 100%;
    }
}

/* Add visual indication for scrollable content */
@media (max-width: 900px) {
    .ldv-related-images::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 30px;
        background: linear-gradient(to right, transparent, var(--card-background));
        pointer-events: none;
        opacity: 0.8;
    }
}

.ldv-alert {
    margin-bottom: 0.2rem;
    margin-top: -1rem;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.ldv-alert-error,
.ldv-alert-info {
    width: 100%;
    text-align: end;
    color: var(--text-primary);
    font-size: 0.7rem;
}

.ldv-alert-error {
    bottom: -10px;
}

.ldv-alert-content {
    flex: 1;
}

.ldv-alert-details {
    margin-top: 4px;
    font-size: 0.9em;
}

/* Mobile styles */
@media (max-width: 900px) {
    .ldv-layout {
        flex-direction: column;
    }

    .ldv-related-images {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--input-border);
        padding-right: 0;
        padding-bottom: 1rem;
        max-height: 220px;
    }

    .ldv-images-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0.5rem;
    }

    .ldv-images-row {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        height: 180px;
    }

    .ldv-related-item {
        flex: 0 0 auto;
        height: 100%;
    }

    .ldv-related-item .ic-image.ic-loaded {
        height: 100%;
        width: auto;
    }

    .ldv-related-item .image-card {
        height: 100%;
    }

    .ldv-related-item .image-card-image-container {
        height: 100%;
        width: auto;
    }

    .ldv-related-item .ic-image.ic-loaded,
    .ldv-related-item .image-card-image-container,
    .ldv-related-item .ic-image-card,
    .ldv-related-item .ic-image-wrapper,
    .ldv-related-item .lazyload-wrapper {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 786px) {
    .ldv-related-item {
        max-width: 150px;
    }
}

/* Smaller mobile screens */
@media (max-width: 640px) {
    .ldv-related-images {
        max-height: 200px;
    }

    .ldv-images-row {
        height: 160px;
    }
}

/* Scrollbar styling */
.ldv-images-scroll::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

.ldv-images-scroll::-webkit-scrollbar-track {
    background: var(--background-primary-800);
    border-radius: 3px;
}

.ldv-images-scroll::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 3px;
}

.ldv-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 1.5rem;
    padding: 0 2rem;
}

.ldv-close-button,
.ldv-previous-button {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 0.5rem;
    background-color: var(--card-background);
    color: var(--text-primary);
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.ldv-close-button:hover,
.ldv-previous-button:hover {
    transform: translateY(-1px);
    background-color: var(--button-background);
    color: var(--button-text);
}

.ldv-close-button {
    left: auto;
    top: 1rem;
    right: 1rem;
    z-index: 99;
}

.ldv-title {
    margin: 0;
}

/* Add these new styles to your existing DesignViewer.css file */

.ldv-related-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.ldv-toggle-similar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    background: var(--card-background);
    border: 1px solid var(--input-border);
    border-radius: 50%;
    transition: all 0.3s ease;
}

.ldv-toggle-similar svg {
    transition: transform 0.3s ease;
    color: var(--text-primary);
}

.ldv-toggle-similar.open svg {
    transform: rotate(180deg);
}

.ldv-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: #fef2f2;
    border-radius: 0.375rem;
}

.ldv-error-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    max-width: 400px;
}

.ldv-error-icon {
    color: #ef4444;
    flex-shrink: 0;
}

.ldv-error-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #dc2626;
    margin: 0 0 0.5rem 0;
}

.ldv-error-description {
    font-size: 0.875rem;
    color: #991b1b;
    margin: 0;
}

.ldv-related-images {
    width: 300px;
    flex-shrink: 0;
    border-right: 1px solid var(--input-border);
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease, opacity 0.3s ease;
}

.ldv-related-images:not(.open) {
    width: 60px;
    padding-right: 0.5rem;
}

.ldv-related-images:not(.open) .ldv-related-title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    margin-bottom: 0;
}

.ldv-related-images:not(.open) .ldv-images-scroll,
.ldv-related-images:not(.open) .ldv-loading-related,
.ldv-related-images:not(.open) .ldv-no-related {
    display: none;
}

.ldv-prompt-suggestions {
    position: absolute;
    top: -23px;
    left: 20px;
    right: 0;
    display: flex;
    overflow-x: auto;
    gap: 8px;
    padding: 8px 0;
    z-index: 10;
}

.ldv-prompt-suggestion {
    flex-shrink: 0;
    padding: 6px 12px;
    background-color: var(--background-secondary);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
}

.ldv-prompt-suggestion:hover {
    background-color: var(--button-background);
    color: var(--button-text);
}

.ldv-prompt-suggestions::-webkit-scrollbar {
    height: 4px;
}

.ldv-prompt-suggestions::-webkit-scrollbar-track {
    background: var(--button-text);
}

.ldv-prompt-suggestions::-webkit-scrollbar-thumb {
    background: var(--button-backgroun);
    border-radius: 2px;
}


@media (max-width: 786px) {
    .ldv-related-images {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--input-border);
        padding-right: 0;
        padding-bottom: 1rem;
        max-height: 220px;
        transition: max-height 0.3s ease;
    }

    .ldv-related-images:not(.open) {
        width: 100%;
        max-height: 50px;
        padding-bottom: 0.5rem;
    }

    .ldv-related-images:not(.open) .ldv-related-title {
        writing-mode: horizontal-tb;
        transform: none;
    }

    .ldv-toggle-similar {
        transform: rotate(90deg);
    }

    .ldv-toggle-similar.open {
        transform: rotate(90deg);
    }
}

@media (max-width: 786px) {

    .ldv-close-button {
        top: 0.5rem;
        right: 0.5rem;
    }

    .ldv-header {
        padding: 0 1rem;
    }

    .ldv-previous-button {
        position: absolute;
        left: -0.8rem;
        top: -0.8rem;
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }

    .ldv-previous-button span {
        display: none;
    }
}


@media (max-width: 900px) {
    .ldv-images-scroll::-webkit-scrollbar {
        height: 4px;
    }

    .ldv-images-scroll::-webkit-scrollbar-track {
        background: transparent;
    }

    .ldv-images-scroll::-webkit-scrollbar-thumb {
        background-color: var(--input-border);
        border-radius: 2px;
    }
}

@media (max-width: 640px) and (orientation: portrait) {
    .ldv-layout {
        height: calc(100vh - 180px);
    }

    .ldv-related-images {
        max-height: 200px;
    }

    .ldv-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .ldv-image-wrapper {
        flex: 1;
        min-height: 0;
    }
}