.imgen-image-generator {
    min-height: fit-content;
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: column;
    overflow-y: visible;
}

.imgen-image-generator select {
    border-radius: 0.2rem;
}

.imgen-mobile-view {
    display: none;
}

.imgen-desktop-view {
    display: flex;
    flex-direction: row;
}

.imgen-sidebar {
    width: 25%;
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: var(--background-primary);
    box-shadow: var(--card-shadow);
}

.imgen-sidebar.left-sidebar {
    position: fixed;
}

.imgen-sidebar.right-sidebar {
    position: fixed;
    right: 0;
}

.imgen-main-content {
    flex: 1;
    padding: 1rem;
    max-width: 50%;
}

.imgen-prompt-input {
    width: 100%;
    height: 10rem;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    transition: all 0.3s;
    box-shadow: var(--card-shadow);
    resize: none;
    background-color: var(--input-background);
    color: var(--text-primary);
    font-family: inherit;
    font-weight: inherit;
}

.imgen-prompt-input:focus {
    outline: none;
    border-color: var(--text-primary);
    box-shadow: 0 0 0 2px var(--text-secondary);
}

.imgen-word-count-seed-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.imgen-seed-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.imgen-seed-input input {
    width: 100px;
    background-color: var(--input-background);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

.imgen-seed-input label {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

.imgen-seed-input-group {
    display: flex;
    align-items: center;
    gap: 4px;
}

.imgen-seed-refresh-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: var(--text-secondary);
    transition: color 0.2s, transform 0.2s;
}

.imgen-seed-refresh-button:hover {
    color: var(--text-primary);
    transform: rotate(180deg);
}

.imgen-seed-refresh-button:active {
    transform: rotate(180deg) scale(0.95);
}

.imgen-word-count {
    font-size: 0.875rem;
    color: var(--text-secondary);
    text-align: right;
    padding: 0 0.4rem;
}

.imgen-button-group {
    display: flex;
    gap: 0.5rem;
}

.imgen-improve-button,
.imgen-random-button {
    flex: 1;
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s;
}

.imgen-improve-button:hover,
.imgen-random-button:hover {
    background-color: var(--text-primary);
    color: var(--background-primary);
}

.imgen-generate-button {
    width: 100%;
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s;
}

.imgen-generate-button:hover {
    background-color: var(--text-primary);
    color: var(--background-primary);
}

.imgen-generate-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.imgen-settings-select {
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.2rem;
    box-shadow: var(--card-shadow);
    border: 1px solid var(--border-color);
    outline: none;
    background: var(--input-background);
    color: var(--text-primary);
    transition: all 0.2s;
}

.imgen-settings-select:focus {
    outline: none;
    border-color: var(--text-primary);
    box-shadow: 0 0 0 2px var(--text-primary);
}

.imgen-dimension-inputs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.imgen-credit {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 4px;
    font-size: 0.8rem;
    z-index: -1;
    color: var(--text-secondary);
}

.imgen-credit a {
    text-decoration: none;
    color: var(--text-primary);
}

.explicit-content-filter {
    font-size: 0.7rem;
    color: var(--warning);
}

.imgen-dimension-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    transition: all 0.3s;
    box-shadow: var(--card-shadow);
    background-color: var(--input-background);
    color: var(--text-primary);
}

.imgen-dimension-input:focus {
    outline: none;
    border-color: var(--text-primary);
    box-shadow: 0 0 0 2px var(--text-secondary);
}

.imgen-action-buttons {
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
}

.imgen-submit-logo-button,
.imgen-download-button,
.imgen-save-button {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--button-background);
    color: var(--button-text);
    transition: background-color 0.3s;
}

.imgen-submit-logo-button:hover:not(:disabled),
.imgen-download-button:hover,
.imgen-save-button:hover {
    background-color: var(--text-primary);
    color: var(--background-primary);
}

.imgen-generated-image-container {
    background-color: var(--background-primary);
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-left: 52%;
    margin-top: 0;
    width: 101%;
    box-shadow: var(--card-shadow);
}

@media(min-width: 769px) {
    .imgen-mobile-generated-image .imgen-generated-image-container {
        display: none;
    }

    .imgen-generated-images.desktop .imgen-image-title {
        display: block;
        margin-left: 52%;
        width: 100%;
    }
}

.imgen-image-title {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 1rem;
    display: none;
}

.imgen-image-wrapper {
    position: relative;
}

.imgen-generated-image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 0.5rem;
}

.imgen-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.0001);
}

@media (max-width: 768px) {
    .message-display {
        top: 10px;
    }

    .imgen-image-generator {
        width: 100vw;
        margin: 0;
        padding: 0;
    }

    .imgen-desktop-view {
        display: none;
    }

    .imgen-mobile-view {
        display: block;
    }

    .imgen-mobile-tabs {
        display: flex;
        justify-content: space-between;
        margin: auto;
        gap: 0;
        border-top: 2px solid var(--text-primary);
        border-bottom: 2px solid var(--text-primary);
        background-color: var(--background-primary);
        box-shadow: var(--card-shadow);
    }

    .imgen-tab-button {
        flex: 1;
        padding: 0.7rem 1rem;
        background-color: var(--background-secondary);
        outline: none;
        border: 0;
        color: var(--text-primary);
    }

    .imgen-tab-button.active {
        background-color: var(--button-background);
        color: var(--button-text);
    }

    .imgen-mobile-content {
        padding: 1rem;
        background-color: var(--background-primary);
        box-shadow: var(--card-shadow);
    }

    .imgen-mobile-generated-image {
        margin-top: 1rem;
    }

    .imgen-mobile-generated-image .imgen-generated-image-container {
        width: 98vw;
        margin: auto;
        padding: 1rem;
        margin: 0;
    }

    .imgen-mobile-generated-image .imgen-generated-image {
        width: 100%;
        height: auto;
    }

    .imgen-mobile-generated-image .imgen-action-buttons {
        margin-top: 1rem;
    }

    .imgen-mobile-generated-image .imgen-action-buttons button {
        margin-bottom: 0.5rem;
    }

    @media (max-width: 640px) {
        .imgen-prompt-input {
            height: 8rem;
        }

        .imgen-improve-button,
        .imgen-random-button {
            width: 100%;
        }
    }

    .imgen-custom-height-scroll {
        max-height: calc(100vh - 40px);
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--text-primary) var(--background-secondary);
    }

    .imgen-custom-height-scroll::-webkit-scrollbar {
        width: 8px;
    }

    .imgen-custom-height-scroll::-webkit-scrollbar-track {
        background: var(--background-secondary);
    }

    .imgen-custom-height-scroll::-webkit-scrollbar-thumb {
        background-color: var(--text-primary);
        border-radius: 4px;
        border: 2px solid var(--background-secondary);
    }

    .imgen-custom-max-width {
        max-width: 1280px;
        margin: 0 auto;
    }

    .imgen-generated-images.mobile .imgen-image-title {
        display: block;
    }

    .imgen-action-buttons {
        flex-direction: column;
    }
}

.imgen-mobile-prompt-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imgen-mobile-settings-view {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.imgen-loading-spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    min-height: fit-content;
    width: 100%;
    margin: 0;
}

.imgen-images-grid.multi-column .imgen-loading-spinner {
    height: 130px;
    margin-top: 1rem;
}


@media (max-width: 768px) {
    .imgen-mobile-generated-image .imgen-generated-image-container {
        width: 98vw;
        margin: auto;
        padding: 1rem;
        margin: 0;
        position: relative;
    }

    .imgen-mobile-generated-image .imgen-loading-spinner {
        position: relative;
        margin-top: 3rem;
        height: 150px;
        min-height: 300px;
        z-index: 10;
    }

    .imgen-loading-spinner {
        height: 200px;
    }

    .imgen-images-grid.multi-column .imgen-loading-spinner {
        height: 150px;
    }

    .imgen-mobile-generated-image .imgen-loading-spinner {
        height: 150px;
        min-height: 150px;
    }
}

.imgen-seed-disabled {
    color: #aaa !important;
    cursor: not-allowed;
}

.imgen-seed-disabled:hover {
    transform: none;
    color: #aaa;
}

.imgen-images-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.imgen-images-grid.multi-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
}

.imgen-generated-image-container {
    background-color: var(--background-primary);
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-left: 52%;
    margin-top: 0;
    width: 101%;
    box-shadow: var(--card-shadow);
}

.imgen-generated-image-container.grid-item {
    margin-left: 0;
    width: 100%;
}


.imgen-mobile-generated-image .error-container {
    display: none;
}


.error-container {
    padding: 16px;
    border-radius: 8px;
    background-color: #FDF2F2;
    border: 1px solid #FECACA;
    width: 300px;
    margin: auto;
    margin-left: 75%;
}


.error-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.error-icon {
    width: 20px;
    height: 20px;
    color: #F87171;
}

.error-message-content {
    color: #B91C1C;
}

.error-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
}

.error-description {
    font-size: 14px;
    color: #9B2C2C;
}

@media (max-width: 768px) {
    .imgen-images-grid.multi-column {
        grid-template-columns: 1fr;
    }

    .imgen-mobile-generated-image .error-container {
        display: block;
        width: 90%;
        margin-left: auto;
    }

    .imgen-mobile-generated-image .imgen-generated-image-container {
        width: 98vw;
        margin: auto;
        padding: 1rem;
        margin: 0;
    }

    .imgen-mobile-generated-image .imgen-generated-image-container.grid-item {
        width: 100%;
    }
}


@media (min-width: 769px) {
    .imgen-generated-images.desktop .imgen-images-grid.multi-column {
        margin-left: 52%;
        width: 101%;
    }

    .imgen-generated-images.desktop .imgen-image-title {
        margin-left: 52%;
        width: 100%;
    }
}

.imgen-submit-logo-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.imgen-submit-container {
    position: relative;
    width: 100%;
}

.imgen-submit-group {
    display: flex;
    gap: 8px;
    align-items: center;
}

.imgen-submit-type-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    flex: 1;
}

.imgen-submit-type-button:disabled {
    background-color: #808080;
    cursor: not-allowed;
}

.imgen-submit-type-text {
    margin-right: 8px;
}

.imgen-chevron-icon {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
}

.imgen-dropdown-menu {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
    background-color: var(--background-primary);
    border: 1px solid var(--text-primary);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.imgen-dropdown-item {
    display: block;
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.imgen-dropdown-item:hover {
    background-color: var(--background-secondary);
}

.imgen-submit-button {
    padding: 8px 16px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.imgen-submit-button:disabled {
    background-color: #808080;
    cursor: not-allowed;
}

.imgen-status-messages {
    margin: 0;
    width: 100%;
    max-width: 800px;
    margin: 0 auto .2rem auto;
}

.imgen-alert {
    border-radius: 0.5rem;
}

.imgen-alert-error,
.imgen-alert-info {
    width: 100%;
    text-align: center;
    color: var(--text-primary);
    font-size: 0.7rem;
}


.imgen-alert-content {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.ldv-alert-content a,
.imgen-alert-content a {
    color: var(--text-secondary);

    &:hover {
        text-decoration: none;
    }
}

.imgen-alert-details {
    margin-top: 0.5rem;
    font-size: 0.75rem;
}