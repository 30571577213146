/* ProfileSettings.css */
.settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.settings-popup {
    background-color: var(--background-primary);
    border-radius: 8px;
    padding: 1rem;
    width: 300px;
    box-shadow: var(--card-shadow);
    max-height: 90vh;
    overflow-y: auto;
}

.settings-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 0.75rem;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 4px;
}

.settings-button:hover {
    background-color: var(--background-secondary);
}

.settings-button.delete {
    color: var(--error);
    margin-top: 3rem;
}

.settings-button.delete:hover {
    background-color: var(--neutral-200);
}

.settings-button svg {
    flex-shrink: 0;
}

.settings-button span {
    font-size: 0.95rem;
}

.settings-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}