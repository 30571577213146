.vf-verify-follows-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.vf-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.vf-instructions {
    text-align: center;
    color: #666;
    margin-bottom: 15px;
    font-style: italic;
}

.vf-followers-table {
    width: 100%;
    border-collapse: collapse;
}

.vf-followers-table thead {
    background-color: #f4f4f4;
}

.vf-followers-table th,
.vf-followers-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.vf-instagram-handle {
    color: #0095f6;
    cursor: pointer;
    text-decoration: underline;
}

.vf-instagram-handle:hover {
    color: #00376b;
}

.vf-action-buttons {
    display: flex;
    gap: 10px;
}

.vf-btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.vf-btn-verify {
    background-color: #4CAF50;
    color: white;
}

.vf-btn-verify:hover {
    background-color: #45a049;
}

.vf-btn-unverify {
    background-color: #f44336;
    color: white;
}

.vf-btn-unverify:hover {
    background-color: #d32f2f;
}

.vf-loading,
.vf-error,
.vf-no-requests {
    text-align: center;
    padding: 20px;
    color: #666;
}