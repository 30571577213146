.idv-container {
    padding: 2rem 0.2rem;
    max-width: 1200px;
}

.idv-margin {
    margin: 0px auto;
}

.idv-back-button {
    position: fixed;
    top: 1rem;
    left: 4.5rem;
    z-index: 2001;
    background: var(--background-primary);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: var(--card-shadow);
    color: var(--text-primary);
    transition: all 0.3s ease-in-out;
}


.idv-main-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.idv-main-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    max-height: 300px;
}

.idv-image-info {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.idv-image-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--text-primary);
}

.idv-image-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.idv-action-button {
    display: flex;
    align-items: center;
    background-color: var(--background-secondary);
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--text-primary);
}

.idv-action-button:hover {
    background-color: var(--neutral-300);
}

.idv-action-button svg {
    margin-right: 6px;
}

.idv-action-button.idv-like svg {
    stroke: var(--text-primary);
}

.idv-action-button.idv-liked {
    background-color: var(--error);
    color: var(--neutral-100);
}

.idv-image-tags {
    font-size: 14px;
    color: var(--text-secondary);
}

.idv-related-images-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text-primary);
    text-align: start;
    padding-left: 5px;
    margin: 1rem 0;
    margin-left: 2px;
}

.idv-masonry-grid_column>div {
    margin-bottom: 15px;
}

.idv-error {
    text-align: center;
    font-size: 18px;
    margin-top: 100px;
    color: var(--error);
}

.idv-username-link,
.idv-tag-link {
    cursor: pointer;
    color: var(--info);
    text-decoration: none;
}

.idv-username-link:hover,
.idv-tag-link:hover {
    color: var(--primary-brand-color-dark);
}

/* Skeleton loading styles */
.idv-skeleton-item {
    width: 100%;
    margin-bottom: 15px;
}

.idv-skeleton-image {
    width: 100%;
    height: 200px;
    background-color: var(--neutral-300);
    border-radius: 8px;
    animation: pulse 1s infinite;
}

.idv-skeleton-text {
    width: 80%;
    height: 20px;
    background-color: var(--neutral-300);
    margin-top: 10px;
    border-radius: 4px;
    animation: pulse 1s infinite;
}

.idv-loading {
    position: fixed;
    width: 100%;
    text-align: center;
    top: 30%;
    color: var(--text-primary);
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

@media (max-width: 768px) {
    .idv-margin {
        margin: 40px auto;
    }

    .idv-image-stats {
        align-items: flex-start;
    }

    .idv-action-button {
        margin-bottom: 10px;
    }

    .idv-back-button {
        background: var(--background-primary);
        z-index: 800;
        right: 1rem;
        left: auto;
    }
}