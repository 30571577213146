.tsc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    background-color: var(--background-primary);
    color: var(--text-primary);
    min-height: 400px;
    width: 100%;
    margin-top: 70px;
}

.tsc-content {
    max-width: 1200px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tsc-heading {
    margin-bottom: 1rem;
}

.tsc-description {
    max-width: 800px;
    margin: 0 auto;
    color: var(--text-secondary);
}

.tsc-themes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 0 1rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.tsc-theme-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    padding: 1.5rem;
    border: 2px solid var(--border-color);
    border-radius: 0.5rem;
    background-color: var(--background-secondary);
    color: var(--text-primary);
    transition: all 0.3s ease;
    cursor: pointer;
}

.tsc-theme-button:hover {
    border-color: var(--primary-600);
    transform: translateY(-2px);
    background-color: var(--background-highlight);
}

.tsc-theme-button.active {
    border-color: var(--primary-brand-color);
    background-color: var(--background-highlight);
}

.tsc-theme-button span {
    font-size: 1rem;
    font-weight: 500;
}

/* Match the container width with ImageGeneration and ContentGeneration components */
@media (max-width: 1200px) {
    .tsc-themes-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .tsc-themes-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .tsc-theme-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        width: 80px;
        border: 2px solid var(--border-color);
        border-radius: 0.5rem;
        background-color: var(--background-secondary);
        color: var(--text-primary);
        transition: all 0.3s ease;
        cursor: pointer;
    }
}

@media (max-width: 480px) {
    .tsc-container {
        padding: 2rem 1rem;
    }
}