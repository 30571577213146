.igh-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: var(--background-secondary);
    color: var(--text-primary);
    min-height: 600px;
    height: 100vh;
    padding-bottom: 50px;
}

.igh-left-section {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.igh-images-container {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
}


.igh-image {
    border-radius: 10px;
    box-shadow: var(--card-shadow);
    transition: transform 0.3s;
    position: absolute;
    height: 100%;
    left: 50%;
    top: 50%;
}

.igh-images-container a:nth-child(1) img {
    transform: translate(36%, -59%);
    height: 66%;
    z-index: 8;

    &:hover {
        transform: translate(36%, -59%) scale(1.05);
    }
}

.igh-images-container a:nth-child(2) img {
    transform: translate(-49%, -66%);
    height: 103%;
    z-index: 9;

    &:hover {
        transform: translate(-49%, -66%) scale(1.05);
    }
}

.igh-images-container a:nth-child(3) img {
    transform: translate(-131%, -54%);
    height: 96%;
    z-index: 10;

    &:hover {
        transform: translate(-131%, -54%) scale(1.05);
    }
}

.igh-images-container a img:hover {
    z-index: 11;
    ;
}

.igh-image-link {
    display: block;
}

.igh-right-section {
    flex: 1 1;
    padding-left: 20px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;
    z-index: 8;
    background: inherit;

}

.igh-heading {
    margin-bottom: 15px;
}

.igh-description {
    color: var(--text-secondary);
}

.igh-button a {
    text-decoration: none;
    border-radius: 0.3rem;
    padding: 1rem 1rem;
    color: var(--button-text);
    width: fit-content;
    min-width: 150px;
    margin-top: 2rem;
    text-align: center;
    background: var(--button-background);

    &:hover {
        background: var(--primary-700);
    }
}

/* Responsive styles for ImageGeneration.css */
@media screen and (max-width: 1024px) {
    .igh-container {
        flex-direction: column;
        height: auto;
        padding: 20px;
        padding-top: 50px;
        gap: 0;
        padding-bottom: 70px;
    }

    .igh-left-section {
        width: 100%;
    }

    .igh-right-section {
        padding: 0;
        width: 100%;
        height: auto;
        text-align: center;
        align-items: center;
    }

    .igh-images-container {
        height: 300px;
        margin-bottom: 0rem;
    }

    .igh-images-container a:nth-child(1) img {
        transform: translate(15%, -27%);
        height: 63%;
    }

    .igh-images-container a:nth-child(2) img {
        transform: translate(-49%, -66%);
        height: 90%;
    }

    .igh-images-container a:nth-child(3) img {
        transform: translate(-129%, -57%);
        height: 90%;
    }
}

@media screen and (max-width: 480px) {
    .igh-images-container {
        height: 250px;
    }

    .igh-images-container a:nth-child(1) img {
        transform: translate(14%, -25%);
        height: 59%;
    }

    .igh-images-container a:nth-child(2) img {
        transform: translate(-49%, -66%);
        height: 80%;
    }

    .igh-images-container a:nth-child(3) img {
        transform: translate(-122%, -57%);
        height: 80%;
    }

    .igh-button a {
        width: 100%;
        max-width: 300px;
    }
}